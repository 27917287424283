import { Close, FormatListNumbered } from '@mui/icons-material'
import { Alert, Box, Button, MenuItem, Select, Stack, Typography } from '@mui/material'
import { usePredictorApi } from 'modules/api'
import { updateGroupPositions } from 'modules/api/requests/admin'
import { GroupTeam } from 'modules/api/types'
import { GroupPositionOverrides, UpdateGroupPositionsRequest } from 'modules/api/types/admin'
import { TeamName } from 'components'
import { ModalAction, ModalFooter, useModalContext } from 'modules/ui'
import React, { useState } from 'react'
import { isSuccessStatusCode } from 'utils/request-utils'
import { GroupView } from '../types/group'

interface IProps {
  group: GroupView
  tournamentId: string
  onUpdate?: () => void
}

interface PositionSelect {
  index: number
  position: number
  team: GroupTeam
}

const OverrideGroupPositions: React.FC<IProps> = ({ group, tournamentId, onUpdate }) => {
  const { closeModal } = useModalContext()

  const generateSelects = (): PositionSelect[] => {
    return group.teams
      .sort((t1, t2) => t1.position - t2.position)
      .map((team, index) => ({
        index,
        team: team,
        position: team.position,
      }))
  }

  const [selects, setSelects] = useState<PositionSelect[]>(generateSelects())

  const onSelectChange = (select: PositionSelect, value: string) => {
    const newSelects = [...selects]

    const newPositionSelect = newSelects.find((s) => s.position === select.position)
    const oldPositionSelect = newSelects.find((s) => s.team.teamId === value)

    if (!newPositionSelect || !oldPositionSelect) throw new Error('Something went wrong when changing positions.')

    const teamToReplace = { ...select.team }
    newPositionSelect.team = { ...oldPositionSelect.team }
    oldPositionSelect.team = teamToReplace

    setSelects(newSelects)
  }

  const apiUpdatePositions = usePredictorApi({ successMessage: 'Positions updated.' })

  const getOverridesForRequest = (clearOverrides = false): GroupPositionOverrides => {
    const overrides: GroupPositionOverrides = {}
    selects.every((select) => {
      overrides[select.team.teamId] = clearOverrides ? 0 : select.position
      return true
    })
    return overrides
  }

  const clearOverrides = async () => {
    const request: UpdateGroupPositionsRequest = {
      group: group.groupName,
      groupPositionOverrides: getOverridesForRequest(true),
      confirmedFinalGroupPositions: false,
    }
    const response = await apiUpdatePositions.requestWithParams((client) => updateGroupPositions(client, tournamentId, request))
    if (isSuccessStatusCode(response)) {
      onUpdate && onUpdate()
      closeModal()
    }
  }

  const onUpdateClick = async () => {
    const request: UpdateGroupPositionsRequest = {
      group: group.groupName,
      groupPositionOverrides: getOverridesForRequest(),
      confirmedFinalGroupPositions: false,
    }
    const response = await apiUpdatePositions.requestWithParams((client) => updateGroupPositions(client, tournamentId, request))
    if (isSuccessStatusCode(response)) onUpdate && onUpdate()
  }

  const actions: ModalAction[] = [
    {
      key: 'update',
      title: 'Update positions',
      buttonProps: { variant: 'contained', endIcon: <FormatListNumbered /> },
      onActionClick: onUpdateClick,
    },
    {
      key: 'cancel',
      title: 'Cancel',
      buttonProps: { variant: 'outlined', endIcon: <Close /> },
    },
  ]

  return (
    <>
      <Box sx={{ flexGrow: 1, p: 1, pt: 3, overflow: 'auto', minHeight: '100px' }}>
        <Stack gap={2} alignItems='center'>
          {group.positionOverride && (
            <Alert
              severity='warning'
              sx={{ alignItems: 'center', '.MuiAlert-action': { py: 0 } }}
              action={
                <Button color='inherit' onClick={clearOverrides}>
                  Clear
                </Button>
              }
            >
              <Typography variant='body2'>Positions have been overridden for this group.</Typography>
            </Alert>
          )}
          {selects.map((select) => (
            <Stack key={select.index} direction='row' alignItems='center' gap={3}>
              <Typography variant='h6'>{select.position}</Typography>
              <Select value={select.team.teamId} sx={{ width: '200px' }} onChange={(e) => onSelectChange(select, e.target.value)}>
                {group.teams.map((team) => (
                  <MenuItem key={team.teamId} value={team.teamId}>
                    <TeamName team={team} />
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          ))}
        </Stack>
      </Box>
      <ModalFooter actions={actions} />
    </>
  )
}

export default OverrideGroupPositions
