import { apiDelete, apiGet, apiPatch, apiPost } from 'modules/api/predictor-api-client'
import { FixturePatchRequest, GoalInsertRequest } from 'modules/api/types/admin'
import { AxiosInstance } from 'axios'

const tournamentEndpoint = (tournamentId: string) => `/admin/tournament/${tournamentId}/fixture`
const fixtureEndpoint = (fixtureId?: string) => `/admin/fixture${fixtureId ? `/${fixtureId}` : ''}`
const goalEndpoint = (goalId?: string) => `/admin/goal${goalId ? `/${goalId}` : ''}`

const getTournamentFixtures = async (client: AxiosInstance, tournamentId: string) => await apiGet(client, tournamentEndpoint(tournamentId))
const getFixture = async (client: AxiosInstance, fixtureId: string) => await apiGet(client, fixtureEndpoint(fixtureId))

const patchFixture = async (client: AxiosInstance, fixtureId: string, patchRequest: FixturePatchRequest) =>
  await apiPatch(client, fixtureEndpoint(fixtureId), patchRequest)

const updateScoreFromGoals = async (client: AxiosInstance, fixtureId: string) =>
  await apiPatch(client, `${fixtureEndpoint(fixtureId)}/updateGoals`)

const submitFixtureUpdate = async (client: AxiosInstance, fixtureId: string) =>
  await apiPost(client, `${fixtureEndpoint(fixtureId)}/runProcess`)

const addGoal = async (client: AxiosInstance, request: GoalInsertRequest) => await apiPost(client, goalEndpoint(), request)

const deleteGoal = async (client: AxiosInstance, goalId: string) => await apiDelete(client, goalEndpoint(goalId))

export { getTournamentFixtures, getFixture, patchFixture, updateScoreFromGoals, submitFixtureUpdate, addGoal, deleteGoal }
