import { PREDICTOR_ROUTE_COLLECTION, STYLE_SETTINGS } from 'predictor-constants'
import { Stack } from '@mui/material'
import { PageButton, PageTitle } from 'components'
import { usePredictorApi } from 'modules/api'
import { getAllTournaments } from 'modules/api/requests/admin'
import { Tournament } from 'modules/api/types/admin'
import { useNavigate } from 'react-router-dom'
import { Container } from '@mui/system'
import { TournamentListItem } from '../components'
import { usePredictorContext } from 'context/PredictorContext'

const TournamentList = () => {
  const { mainContentRef, setActiveTournament } = usePredictorContext()
  const tournamentListApi = usePredictorApi<Tournament[]>({ apiFunc: getAllTournaments })
  const tournaments = tournamentListApi.data
  const navigate = useNavigate()

  const onTitleClick = (tournament: Tournament) => {
    setActiveTournament(tournament)
  }

  return (
    <>
      <PageTitle title={PREDICTOR_ROUTE_COLLECTION.tournaments.title} icon={PREDICTOR_ROUTE_COLLECTION.tournaments.icon} />
      <PageButton title='Create' tooltip='Create tournament' onClick={() => navigate(PREDICTOR_ROUTE_COLLECTION.newTournament.path)} />

      <Container maxWidth='md' sx={{ pb: STYLE_SETTINGS.PAGE_BUTTON_SPACE }}>
        <Stack gap={2} alignItems='center' ref={mainContentRef}>
          {tournaments && (
            <>
              {tournaments.map((tournament) => (
                <TournamentListItem key={tournament.id} tournament={tournament} onTitleClick={onTitleClick} />
              ))}
            </>
          )}
        </Stack>
      </Container>
    </>
  )
}

export default TournamentList
