import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { GoalType } from 'modules/api/types/admin'
import React from 'react'
import { FormFieldProps } from 'types/form'

const GoalTypeField: React.FC<FormFieldProps> = ({ field, onChange }) => {
  const goalTypeOption = (value: GoalType, display: string) => ({ value, display })

  const goalTypes: { value: GoalType; display: string }[] = [
    goalTypeOption('GoalInNormalTime', 'Goal in Normal Time'),
    goalTypeOption('PenaltyInNormalTime', 'Penalty in Normal Time'),
    goalTypeOption('GoalInExtraTime', 'Goal in Extra Time'),
    goalTypeOption('PenaltyInExtraTime', 'Penalty in Extra Time'),
    goalTypeOption('PenaltyInPenaltyShootout', 'Penalty in Penalty Shootout'),
  ]

  return (
    <FormControl error={!field.isValid}>
      <InputLabel id='goal-type-label'>Team</InputLabel>
      <Select labelId='goal-type-label' id='goal-type' value={field.value} label='Goal Type' onChange={(e) => onChange(e.target.value)}>
        {goalTypes.map((gt) => (
          <MenuItem key={gt.value} value={gt.value}>
            {gt.display}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default GoalTypeField
