import { CheckCircle, FormatListNumbered, InfoRounded, MoreHoriz, PlaylistAddCheck } from '@mui/icons-material'
import { Divider, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { usePredictorApi } from 'modules/api'
import { updateGroupPositions } from 'modules/api/requests/admin'
import { useModalContext, useConfirmationModalContext, useItemMenuContext } from 'modules/ui'
import React from 'react'
import { ItemMenuItem } from 'types/item-menu'
import { isSuccessStatusCode } from 'utils/request-utils'
import { GroupView } from '../types/group'
import GroupTable from './GroupTable'
import OverrideGroupPositions from './OverrideGroupPositions'

interface IProps {
  tournamentId: string
  group: GroupView
  onGroupUpdate?: () => void
}

const Group: React.FC<IProps> = ({ tournamentId, group, onGroupUpdate }) => {
  const { openMenu } = useItemMenuContext()
  const { openModal } = useModalContext()
  const { showConfirm } = useConfirmationModalContext()
  const apiConfirmPositions = usePredictorApi({ successMessage: 'Group positions confirmed.' })

  const confirmPositions = async () => {
    const confirmed = await showConfirm({
      title: 'Confirm group positions',
      message: `Are you sure you want to confirm the group positions for group ${group.groupName}? This will update knockout and points.`,
      confirmText: 'Confirm positions',
    })

    if (!confirmed) return

    const resp = await apiConfirmPositions.requestWithParams((client) =>
      updateGroupPositions(client, tournamentId, { group: group.groupName, confirmedFinalGroupPositions: true }),
    )
    if (isSuccessStatusCode(resp)) onGroupUpdate && onGroupUpdate()
  }

  const openEditPositionsModal = () => {
    openModal({
      title: `Group ${group.groupName} - Positions`,
      contentComponent: (
        <OverrideGroupPositions group={group} tournamentId={tournamentId} onUpdate={() => onGroupUpdate && onGroupUpdate()} />
      ),
      hideFooter: true,
    })
  }

  const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    const actions: ItemMenuItem[] = [
      {
        key: 'edit-positions',
        icon: <FormatListNumbered />,
        title: 'Override positions',
        onClick: openEditPositionsModal,
      },
    ]

    if (!group.positionsConfirmed)
      actions.push({
        key: 'confirm-positions',
        icon: <PlaylistAddCheck color='secondary' />,
        title: <Typography color='secondary'>Confirm positions</Typography>,
        onClick: confirmPositions,
      })

    openMenu(event.currentTarget, null, actions)
  }

  return (
    // right padding fix for scrollbar
    <Paper elevation={2} sx={{ width: { xs: 'calc(100vw - 18px)', sm: 'inherit' }, mx: { xs: '6px', md: 0 } }}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        py={1}
        px={2}
        sx={{ boxShadow: 2, backgroundColor: 'emphasis' }}
      >
        <Stack flexGrow={1} direction='row' alignItems='center' gap={1}>
          <Typography variant='h6'>Group {group.groupName}</Typography>
          <Divider orientation='vertical' flexItem />
          <Stack direction='column' justifyContent='space-between' alignItems='start'>
            <Stack direction='row' alignItems='center' gap={0.5}>
              <Typography variant='caption' color={group.positionsConfirmed ? 'success.main' : 'grey.600'}>
                Positions {group.positionsConfirmed ? 'confirmed' : 'uncomfirmed'}
              </Typography>
              {group.positionsConfirmed && (
                <Tooltip title={`Positions are ${group.positionsConfirmed ? '' : 'not '}confirmed.`}>
                  <CheckCircle fontSize='small' sx={{ color: 'success.main' }} />
                </Tooltip>
              )}
            </Stack>
            {group.positionOverride && (
              <>
                <Stack direction='row' justifyContent='space-between' alignItems='center' gap={0.5} sx={{ color: 'secondary.main' }}>
                  <Typography variant='caption'>Position Override</Typography>
                  <Tooltip title='Positions have been overridden. Go to `Override Positions` to clear.'>
                    <InfoRounded fontSize='small' />
                  </Tooltip>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>

        <IconButton edge='end' onClick={onOpenMenu} sx={{ flexShrink: 0 }}>
          <MoreHoriz />
        </IconButton>
      </Stack>

      <GroupTable group={group} />
    </Paper>
  )
}

export default Group
