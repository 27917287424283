import { Stack, Theme, Typography, TypographyTypeMap, useMediaQuery } from '@mui/material'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'
import { Goal } from 'modules/api/types/admin'
import React from 'react'
import { getShortScorerName } from '../utils/fixture-utils'

interface IProps {
  goal: Goal
  typographyProps?: DefaultComponentProps<TypographyTypeMap>
}

const GoalDisplay: React.FC<IProps> = ({ goal, typographyProps }) => {
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const isPenaltyGoal = (goal: Goal) => ['PenaltyInNormalTime', 'PenaltyInExtraTime'].includes(goal.type)

  return (
    <Stack direction='row' gap={1}>
      <Typography variant='caption' {...typographyProps}>
        {isSmUp ? goal.scorerName : getShortScorerName(goal.scorerName)}
      </Typography>
      <Typography variant='caption' {...typographyProps} sx={{ fontWeight: 'bold' }}>
        {goal.time}&apos; {isPenaltyGoal(goal) && ' (pen)'}
      </Typography>
    </Stack>
  )
}

export default GoalDisplay
