import { Backdrop, CircularProgress, SxProps, Theme } from '@mui/material'
import React from 'react'

interface IProps {
  hideBackground?: boolean
}

const LoadingBackdrop: React.FC<IProps> = ({ hideBackground }) => {
  const sx: SxProps<Theme> = {
    color: (theme: Theme) => theme.palette.primary.main,
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  }
  if (hideBackground) sx.background = (theme) => theme.palette.background.default
  return (
    <Backdrop sx={sx} open>
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}

export default LoadingBackdrop
