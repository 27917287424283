import { TextField } from '@mui/material'
import React from 'react'
import { FormFieldProps } from 'types/form'

const PlayerNameField: React.FC<FormFieldProps> = ({ field, onChange }) => {
  return (
    <TextField
      id='player-name'
      label='Player Name'
      required
      error={!field.isValid}
      helperText={field.isValid ? '' : field.validationErrors[0]}
      value={field.value}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export default PlayerNameField
