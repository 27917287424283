import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { GroupTeam } from 'modules/api/types'
import { TeamName } from 'components'
import React, { ReactElement } from 'react'
import { GroupView } from '../types/group'

interface Row {
  team: GroupTeam
  cells: { [name: string]: string | number | ReactElement }
}

interface IProps {
  group: GroupView
}

const GroupTable: React.FC<IProps> = ({ group }) => {
  const tableHead: { [name: string]: string } = {
    PTS: 'Points',
    GP: 'Games Played',
    GD: 'Goal Difference',
    W: 'Wins',
    D: 'Draws',
    L: 'Losses',
    GF: 'Goals For',
    GA: 'Goals Against',
  }

  const tableRows: Row[] = group.teams.map((team) => {
    const gd = team.goalsFor - team.goalsAgainst
    return {
      team: team,
      cells: {
        PTS: team.points,
        GP: team.gamesPlayed,
        W: team.wins,
        D: team.draws,
        L: team.losses,
        GF: team.goalsFor,
        GA: team.goalsAgainst,
        GD: `${gd > 0 ? '+' : ''}${gd}`,
      },
    }
  })

  return (
    <Table sx={{ width: '100%', display: { xs: 'block', md: 'table' }, overflowX: 'auto' }}>
      <TableHead>
        <TableRow>
          <TableCell width='10px'>#</TableCell>
          <TableCell>Team</TableCell>
          {Object.keys(tableHead).map((h) => (
            <TableCell width='20px' key={h}>
              <Tooltip title={tableHead[h]}>
                <Box>{h}</Box>
              </Tooltip>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableRows.map((row) => (
          <TableRow key={row.team.teamId} sx={{ '&:nth-of-type(even)': { backgroundColor: 'focus' } }}>
            <TableCell>
              <Typography sx={row.team.positionOverride ? { fontWeight: 'bold', color: 'secondary.main' } : {}}>
                {row.team.position === 0 ? '-' : row.team.position}
              </Typography>
            </TableCell>
            <TableCell>
              <TeamName team={row.team} />
            </TableCell>
            {Object.keys(tableHead).map((h) => (
              <TableCell key={h} align='center'>
                <Tooltip title={tableHead[h]}>
                  <Box sx={{ fontWeight: h === 'PTS' ? 'bold' : 'inherit' }}>{row.cells[h]}</Box>
                </Tooltip>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default GroupTable
