import { TextField } from '@mui/material'
import { Team } from 'modules/api/types/admin'
import { TeamName } from 'components'
import React from 'react'
import { FormFieldProps } from 'types/form'

interface IProps extends FormFieldProps {
  team: Team
}

const ScoreField: React.FC<IProps> = ({ field, team, onChange }) => {
  return (
    <TextField
      type='number'
      id={team ? `${team.id}-score` : 'Score'}
      label={team ? <TeamName team={team} /> : 'Score'}
      variant='standard'
      error={!field.isValid}
      helperText={field.isValid ? 'The score until 90 mins + injury time.' : field.validationErrors[0]}
      value={field.value}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export default ScoreField
