import { TextField } from '@mui/material'
import { INPUT_DATE_FORMAT } from 'predictor-constants'
import React from 'react'
import { FormFieldProps } from 'types/form'

const FixtureDateField: React.FC<FormFieldProps> = ({ field, onChange }) => {
  const defaultHelperText = () => (
    <>
      The date of the fixture in <strong> local time </strong>. This may affect the prediction date limit. Use format{' '}
      <strong>{INPUT_DATE_FORMAT}</strong>
    </>
  )

  return (
    <TextField
      id='fixture-date'
      label='Fixture Date'
      variant='standard'
      error={!field.isValid}
      helperText={field.isValid ? defaultHelperText() : field.validationErrors[0]}
      required
      value={field.value}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export default FixtureDateField
