import { Alert, Container, Divider, Hidden, IconButton, Stack, Typography, TextField, useMediaQuery, Theme } from '@mui/material'
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid'
import { usePredictorApi } from 'modules/api'
import { LoadingBox, PageTitle } from 'components'
import { DISPLAY_DATE_FORMAT_S, PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { useEffect, useState } from 'react'
import { DataGridState } from 'types/data-grid'
import { format as formatDate } from 'date-fns'
import { useModalContext } from 'modules/ui'
import { RefreshRounded } from '@mui/icons-material'
import { SearchResult } from 'types/search'
import { PredictionTypeSelect } from '../components'
import { getPredictionInputLogEntries } from 'modules/api/requests/admin'
import { PredictionInputLogEntry } from 'modules/api/types/admin'
import { getEntryModalConfig, renderEntryParticipant } from '../utils'

const PredictionInputLog = () => {
  const [dataGridState, setDataGridState] = useState<DataGridState>({
    pageSize: 20,
    page: 0,
  })
  const { openModal } = useModalContext()
  const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const apiGetEntries = usePredictorApi<SearchResult<PredictionInputLogEntry>>({
    apiFunc: (client) => getPredictionInputLogEntries(client, 20, 1),
  })
  const entries = apiGetEntries.data

  const columns: GridColDef[] = [
    {
      field: 'dateTime',
      headerName: 'Date',
      width: 190,
      renderCell: (params) => formatDate(new Date((params.row as PredictionInputLogEntry).dateTime), DISPLAY_DATE_FORMAT_S),
      sortable: false,
    },
    {
      field: 'gameParticipantId',
      headerName: 'Participant',
      width: 200,
      renderCell: (params) => renderEntryParticipant(params.row as PredictionInputLogEntry),
      sortable: false,
    },
    { field: 'predictionType', headerName: 'Type', width: 150, sortable: false },
    { field: 'inputOperation', headerName: 'Operation', width: 150, sortable: false },
    { field: 'inputStatus', headerName: 'Status', width: 150, sortable: false },
    { field: 'inputData', headerName: 'Input Data', sortable: false, minWidth: 200, flex: 1 },
  ]

  const onRowClick = (params: GridRowParams) => openModal(getEntryModalConfig(params.row as PredictionInputLogEntry))

  useEffect(() => {
    if (!dataGridState) return
    apiGetEntries.requestWithParams((client) =>
      getPredictionInputLogEntries(
        client,
        dataGridState.pageSize,
        dataGridState.page + 1,
        dataGridState.serverSideFilter?.participant,
        dataGridState.serverSideFilter?.predictionType,
      ),
    )
  }, [dataGridState])

  return (
    <Container>
      <PageTitle
        title={
          <Stack direction='row' alignItems='center' gap={1}>
            <Typography>{PREDICTOR_ROUTE_COLLECTION.predictionInputLog.title}</Typography>
            <Hidden smDown>
              <Divider flexItem orientation='vertical' variant='middle' />
              <IconButton onClick={() => apiGetEntries.request()}>
                <RefreshRounded className={apiGetEntries.isLoading ? 'spin' : ''} />
              </IconButton>
            </Hidden>
          </Stack>
        }
        icon={PREDICTOR_ROUTE_COLLECTION.predictionInputLog.icon}
      />

      {apiGetEntries.isLoading && <LoadingBox />}
      {apiGetEntries.error && <Alert severity='error'>{apiGetEntries.error}</Alert>}
      <Stack gap={1} alignItems='center' justifyContent='center' direction={isMdDown ? 'column' : 'row'} mb={2}>
        <PredictionTypeSelect
          value={dataGridState.serverSideFilter?.predictionType?.split('|') ?? []}
          onChange={(value) =>
            setDataGridState({
              ...dataGridState,
              serverSideFilter: {
                ...dataGridState.serverSideFilter,
                predictionType: value === undefined ? undefined : value.filter((x) => Boolean(x)).join('|'),
              },
            })
          }
        />
        <TextField
          variant='outlined'
          label='Participant'
          value={dataGridState.serverSideFilter?.participant}
          sx={{ width: 200 }}
          onChange={(e) =>
            setDataGridState({ ...dataGridState, serverSideFilter: { ...dataGridState.serverSideFilter, participant: e.target.value } })
          }
        />
      </Stack>

      {!apiGetEntries.isLoading && entries && (
        <DataGrid
          rows={entries.pageResult}
          rowCount={entries.totalCount}
          columns={columns}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
              '&:nth-of-type(even):not(:hover)': { backgroundColor: 'focus' },
              '&:hover': { backgroundColor: 'emphasis' },
            },
          }}
          rowHeight={30}
          autoHeight
          onRowClick={onRowClick}
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={dataGridState.pageSize}
          page={dataGridState.page}
          filterModel={dataGridState.filter}
          sortModel={dataGridState.sort}
          onPageSizeChange={(pageSize) => setDataGridState({ ...dataGridState, pageSize: pageSize })}
          onPageChange={(page) => setDataGridState({ ...dataGridState, page: page })}
          onFilterModelChange={(filter) => setDataGridState({ ...dataGridState, filter: filter })}
          onSortModelChange={(sort) => setDataGridState({ ...dataGridState, sort: sort })}
          paginationMode='server'
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
        />
      )}
    </Container>
  )
}

export default PredictionInputLog
