import { FormControl, MenuItem, Select } from '@mui/material'
import React from 'react'

interface IProps {
  newPlayerName: string
  playerNames: string[]
  onChange: (newPlayerName: string) => void
}

const ScorerValidationNewNameField: React.FC<IProps> = ({ newPlayerName, playerNames, onChange }) => {
  return (
    <FormControl sx={{ width: '180px' }}>
      <Select value={newPlayerName} label='New name' onChange={(e) => onChange(e.target.value)}>
        {playerNames.map((x) => (
          <MenuItem key={x} value={x}>
            {x}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ScorerValidationNewNameField
