import { Alert, Container, Divider, Hidden, IconButton, Stack, Typography, TextField, useMediaQuery, Theme } from '@mui/material'
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid'
import { usePredictorApi } from 'modules/api'
import { getProcesses } from 'modules/api/requests/admin'
import { Process } from 'modules/api/types/admin'
import { LoadingBox, PageTitle } from 'components'
import { DISPLAY_DATE_FORMAT, PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { useEffect, useState } from 'react'
import { DataGridState } from 'types/data-grid'
import { format as formatDate } from 'date-fns'
import { useModalContext } from 'modules/ui'
import { getProcessModalConfig, renderProcessStatus } from '../utils/process-utils'
import { RefreshRounded } from '@mui/icons-material'
import { SearchResult } from 'types/search'
import { ProcessStatusSelect } from '../components'
import ProcessNameSelect from '../components/ProcessNameSelect'

const ProcessList = () => {
  const [dataGridState, setDataGridState] = useState<DataGridState>({
    pageSize: 20,
    page: 0,
  })
  const { openModal } = useModalContext()
  const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const apiGetProcesses = usePredictorApi<SearchResult<Process>>({ apiFunc: (client) => getProcesses(client, 20, 1) })
  const processes = apiGetProcesses.data

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params) => renderProcessStatus(params.row as Process),
      sortable: false,
    },
    { field: 'name', headerName: 'Name', width: 220, sortable: false },
    { field: 'runTimeInMs', headerName: 'Run time (ms)', width: 110, sortable: false, filterable: false },
    { field: 'processContext', headerName: 'Context', width: 300, flex: 1, sortable: false, filterable: false },
    {
      field: 'utcAddedTime',
      headerName: 'Added time',
      width: 130,
      renderCell: (params) => formatDate(new Date((params.row as Process).utcAddedTime), DISPLAY_DATE_FORMAT),
      sortable: false,
      filterable: false,
    },
    {
      field: 'chainId',
      headerName: 'Chain',
      width: 180,
      sortable: false,
      filterable: false,
    },
  ]

  const onRowClick = (params: GridRowParams) => {
    const process = params.row as Process
    openModal(getProcessModalConfig(process))
  }

  useEffect(() => {
    if (!dataGridState) return
    apiGetProcesses.requestWithParams((client) =>
      getProcesses(
        client,
        dataGridState.pageSize,
        dataGridState.page + 1,
        dataGridState.serverSideFilter?.status,
        dataGridState.serverSideFilter?.name,
        dataGridState.serverSideFilter?.chain,
      ),
    )
  }, [dataGridState])

  return (
    <Container>
      <PageTitle
        title={
          <Stack direction='row' alignItems='center' gap={1}>
            <Typography>{PREDICTOR_ROUTE_COLLECTION.processes.title}</Typography>
            <Hidden smDown>
              <Divider flexItem orientation='vertical' variant='middle' />
              <IconButton onClick={() => apiGetProcesses.request()}>
                <RefreshRounded className={apiGetProcesses.isLoading ? 'spin' : ''} />
              </IconButton>
            </Hidden>
          </Stack>
        }
        icon={PREDICTOR_ROUTE_COLLECTION.processes.icon}
      />

      {apiGetProcesses.isLoading && <LoadingBox />}
      {apiGetProcesses.error && <Alert severity='error'>{apiGetProcesses.error}</Alert>}
      <Stack gap={1} alignItems='center' justifyContent='center' direction={isMdDown ? 'column' : 'row'} mb={2}>
        <ProcessStatusSelect
          value={dataGridState.serverSideFilter?.status?.split('|') ?? []}
          onChange={(value) =>
            setDataGridState({
              ...dataGridState,
              serverSideFilter: {
                ...dataGridState.serverSideFilter,
                status: value === undefined ? undefined : value.filter((x) => Boolean(x)).join('|'),
              },
            })
          }
        />
        <ProcessNameSelect
          value={dataGridState.serverSideFilter?.name?.split('|') ?? []}
          onChange={(value) =>
            setDataGridState({
              ...dataGridState,
              serverSideFilter: {
                ...dataGridState.serverSideFilter,
                name: value === undefined ? undefined : value.filter((x) => Boolean(x)).join('|'),
              },
            })
          }
        />
        <TextField
          variant='outlined'
          label='Chain'
          value={dataGridState.serverSideFilter?.chain}
          sx={{ width: 200 }}
          onChange={(e) =>
            setDataGridState({ ...dataGridState, serverSideFilter: { ...dataGridState.serverSideFilter, chain: e.target.value } })
          }
        />
      </Stack>

      {!apiGetProcesses.isLoading && processes && (
        <DataGrid
          rows={processes.pageResult}
          rowCount={processes.totalCount}
          columns={columns}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
              '&:nth-of-type(even):not(:hover)': { backgroundColor: 'focus' },
              '&:hover': { backgroundColor: 'emphasis' },
            },
          }}
          rowHeight={30}
          autoHeight
          onRowClick={onRowClick}
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={dataGridState.pageSize}
          page={dataGridState.page}
          filterModel={dataGridState.filter}
          sortModel={dataGridState.sort}
          onPageSizeChange={(pageSize) => setDataGridState({ ...dataGridState, pageSize: pageSize })}
          onPageChange={(page) => setDataGridState({ ...dataGridState, page: page })}
          onFilterModelChange={(filter) => setDataGridState({ ...dataGridState, filter: filter })}
          onSortModelChange={(sort) => setDataGridState({ ...dataGridState, sort: sort })}
          paginationMode='server'
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
        />
      )}
    </Container>
  )
}

export default ProcessList
