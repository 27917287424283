import { useAuth0 } from '@auth0/auth0-react'
import { usePredictorApi } from 'modules/api'
import React, { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ThemeMode } from 'types/context/predictor-context'
import { IPredictorContextState } from '../types/context'
import { PredictorUser } from '../modules/api/types'
import { login, setUserSettings } from 'modules/api/requests'
import { Tournament } from 'modules/api/types/admin'

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <PredictorContext>.')
}

const initialState: IPredictorContextState = { themeMode: 'light', setThemeMode: stub, setActiveTournament: stub }

const PredictorContext = React.createContext<IPredictorContextState>(initialState)

const PredictorContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user: authenticatedUser } = useAuth0()
  const [user, setUser] = useState(initialState.user)
  const [userError, setUserError] = useState<string>()
  const [activeTournament, setActiveTournament] = useState<Tournament>()

  const headerTitleContainerRef = useRef<Element>()
  const mainContainerRef = useRef<HTMLElement>()
  const mainContentRef = useRef<HTMLElement>()
  const rightSidebarContentRef = useRef<HTMLElement>()

  const [themeMode, setThemeModeLocal] = useState(initialState.themeMode)
  const [userSynced, setUserSynced] = useState(false)
  const apiLogin = usePredictorApi({ apiFunc: login })
  const apiSetUserSettings = usePredictorApi()

  useEffect(() => {
    if (apiLogin.data !== undefined) {
      const u = apiLogin.data as PredictorUser
      setUser(u)
      if (u.attributes.theme) setThemeModeLocal(u.attributes.theme as ThemeMode)
    }
  }, [apiLogin.data])

  useEffect(() => {
    if (apiLogin.error !== undefined) {
      setUserError(apiLogin.error)
      setUserSynced(false)
    }
  }, [apiLogin.error])

  useEffect(() => {
    if (!authenticatedUser) {
      if (user !== undefined) setUser(undefined)
      return
    }

    if (user && user.email === authenticatedUser.email) return

    if (!userSynced) {
      setUserError(undefined)
      apiLogin.request()
      setUserSynced(true)
    }
  }, [authenticatedUser])

  const setThemeMode = async (themeModeInput: ThemeMode): Promise<void> => {
    if (themeMode !== themeModeInput) {
      setThemeModeLocal(themeModeInput)
      apiSetUserSettings.requestWithParams((client) => setUserSettings(client, { attributes: { theme: themeModeInput } }))
    }
  }

  const ctx: IPredictorContextState = useMemo(
    () => ({
      user,
      userError,
      themeMode,
      headerTitleContainerRef,
      mainContainerRef,
      mainContentRef,
      rightSidebarContentRef,
      setThemeMode,
      activeTournament,
      setActiveTournament,
    }),
    [user, userError, themeMode, headerTitleContainerRef, mainContainerRef, mainContentRef, rightSidebarContentRef, activeTournament],
  )

  return <PredictorContext.Provider value={ctx}>{children}</PredictorContext.Provider>
}

const usePredictorContext = () => useContext(PredictorContext)

export { usePredictorContext, PredictorContextProvider }
