import { AddCircle, Restore } from '@mui/icons-material'
import { Button, Stack, SxProps, Theme } from '@mui/material'
import { usePredictorApi } from 'modules/api'
import { addGoal, getTournamentPlayers } from 'modules/api/requests/admin'
import { Fixture, GoalInsertRequest, GoalTeamType, GoalType, Player } from 'modules/api/types/admin'
import { useForm } from 'modules/form'
import React, { FormEvent } from 'react'
import { validateRequired } from 'types/form'
import { isSuccessStatusCode } from 'utils/request-utils'
import GoalScorerNameField from './fields/GoalScorerNameField'
import GoalTeamField from './fields/GoalTeamField'
import GoalTimeField from './fields/GoalTimeField'
import GoalTypeField from './fields/GoalTypeField'

interface IProps {
  fixture: Fixture
  onAddGoal?: () => void
  sx?: SxProps<Theme>
}

const AddGoalForm: React.FC<IProps> = ({ fixture, onAddGoal, sx }) => {
  const apiAddGoal = usePredictorApi({ successMessage: 'Goal added.', errorMessage: 'Error adding goal.' })
  const apiGetPlayers = usePredictorApi<Player[]>({ apiFunc: (client) => getTournamentPlayers(client, fixture.tournamentId) })
  const players = apiGetPlayers.data

  const form = useForm({
    team: { defaultValue: 'Home' },
    goalType: { defaultValue: 'GoalInNormalTime' },
    scorerName: { validations: [validateRequired] },
    time: { validations: [validateRequired] },
  })

  const { team, goalType, scorerName, time } = form.fields

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const request: GoalInsertRequest = {
      fixtureId: fixture.id,
      type: goalType.value as GoalType,
      team: team.value as GoalTeamType,
      scorerName: scorerName.value as string,
      time: time.value as string,
    }
    const response = await apiAddGoal.requestWithParams((client) => addGoal(client, request))
    if (isSuccessStatusCode(response)) {
      if (onAddGoal) onAddGoal()
      form.reset()
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={2} sx={sx}>
        <GoalTeamField field={team} onChange={form.set.team} fixture={fixture} />
        <GoalTypeField field={goalType} onChange={form.set.goalType} />
        <GoalScorerNameField
          field={scorerName}
          onChange={form.set.scorerName}
          team={team.value === 'Home' ? fixture.homeTeam : fixture.awayTeam}
          players={players}
        />
        <GoalTimeField field={time} onChange={form.set.time} />
        <Button variant='contained' type='submit' disabled={!form.isValid} endIcon={<AddCircle />}>
          Add Goal
        </Button>
        <Button variant='outlined' endIcon={<Restore />} onClick={() => form.reset()}>
          Reset
        </Button>
      </Stack>
    </form>
  )
}

export default AddGoalForm
