import { DarkMode, LightMode } from '@mui/icons-material'
import { Button, Hidden, IconButton, Tooltip } from '@mui/material'
import { usePredictorContext } from 'context/PredictorContext'
import React from 'react'

const ThemeControl = () => {
  const { themeMode, setThemeMode } = usePredictorContext()

  const toggleTheme = () => {
    if (themeMode === 'light') setThemeMode('dark')
    else setThemeMode('light')
  }

  const tooltipText = `Switch to ${themeMode === 'light' ? 'dark' : 'light'} mode`

  return (
    <>
      <Hidden mdDown>
        <Tooltip title={tooltipText}>
          <Button variant='text' size='small' endIcon={themeMode === 'light' ? <LightMode /> : <DarkMode />} onClick={toggleTheme}>
            {`${themeMode === 'light' ? 'Light' : 'Dark'} theme`}
          </Button>
        </Tooltip>
      </Hidden>
      <Hidden mdUp>
        <Tooltip title={tooltipText}>
          <IconButton size='small' edge='end' color='inherit' aria-label='theme' onClick={toggleTheme}>
            {themeMode === 'light' && (
              <Tooltip title='Switch to dark mode'>
                <DarkMode />
              </Tooltip>
            )}
            {themeMode === 'dark' && (
              <Tooltip title='Switch to light mode'>
                <LightMode />
              </Tooltip>
            )}
          </IconButton>
        </Tooltip>
      </Hidden>
    </>
  )
}

export default ThemeControl
