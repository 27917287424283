import { apiDelete, apiGet, apiPatch, apiPost } from 'modules/api/predictor-api-client'
import { TournamentInsertRequest, TournamentPatchRequest } from 'modules/api/types/admin'
import { AxiosInstance } from 'axios'

const endpointBase = '/admin/tournament'
const endpoint = (tournamentId?: string) => (tournamentId ? `${endpointBase}/${tournamentId}` : endpointBase)

const getTournamentTypes = async (client: AxiosInstance) => await apiGet(client, endpoint('types'))

const getAllTournaments = async (client: AxiosInstance) => await apiGet(client, endpoint())

const getTournament = async (client: AxiosInstance, tournamentId: string) => await apiGet(client, endpoint(tournamentId))

const createTournament = async (client: AxiosInstance, tournamentInsertRequest: TournamentInsertRequest) =>
  await apiPost(client, endpoint(), tournamentInsertRequest)

const patchTournament = async (client: AxiosInstance, tournamentId: string, tournamentPatchRequest: TournamentPatchRequest) =>
  await apiPatch(client, endpoint(tournamentId), tournamentPatchRequest)

const deleteTournament = async (client: AxiosInstance, tournamentId: string) => await apiDelete(client, endpoint(tournamentId))

export { getTournamentTypes, getAllTournaments, getTournament, createTournament, patchTournament, deleteTournament }
