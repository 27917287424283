import { useAuth0 } from '@auth0/auth0-react'
import { Alert, Box, Button, Container, Paper, Stack, Typography } from '@mui/material'
import { APP_DETAILS } from 'predictor-constants'
import React, { useEffect } from 'react'

const Login: React.FC<{ userError?: string }> = ({ userError }): JSX.Element => {
  const { loginWithRedirect, logout, error, isAuthenticated } = useAuth0()

  useEffect(() => {
    localStorage.setItem('redirect-url', window.location.href)
  }, [])

  return (
    <Box
      sx={{
        background: '#EEEEEE',
        color: (theme) => theme.palette.text.primary,
        minHeight: '100vh',
        p: 0,
        m: 0,
      }}
    >
      <Container component='main' maxWidth='xs'>
        <Stack height='100vh' justifyContent='center' p={1}>
          <Paper elevation={4}>
            <Stack
              direction='column'
              gap={3}
              justifyContent='space-between'
              alignItems='center'
              sx={{
                backgroundColor: 'background.default',
                px: 4,
                maxHeight: '80vh',
                minHeight: '40vh',
                py: 5,
              }}
            >
              {APP_DETAILS.MAINLOGO}
              <Typography variant='body1'>{APP_DETAILS.WELCOMEMESSAGE}</Typography>
              {!isAuthenticated && !error && (
                <Button variant='contained' size='large' onClick={() => loginWithRedirect()} fullWidth>
                  Sign in to continue
                </Button>
              )}
              {(error || userError) && (
                <>
                  <Alert severity='error'>
                    {error && error.message}
                    {userError &&
                      (userError === 'Network Error' ? 'Could not connect to server. Please contact administrator.' : userError)}
                  </Alert>
                  <Button variant='contained' size='large' onClick={() => logout({ returnTo: window.location.origin })} fullWidth>
                    Logout
                  </Button>
                </>
              )}
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </Box>
  )
}

export default Login
