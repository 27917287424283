import { Button, Divider, Stack } from '@mui/material'
import React from 'react'
import { useModalContext } from '../context'
import { ModalAction } from '../types'

interface IProps {
  actions?: ModalAction[]
  onCloseModal?: () => void
}

const ModalFooter: React.FC<IProps> = ({ actions, onCloseModal }) => {
  const { closeModal } = useModalContext()

  const onActionClick = (action: ModalAction) => {
    action.onActionClick && action.onActionClick()
    onCloseModal && onCloseModal()
    closeModal()
  }

  return (
    <>
      <Divider sx={{ my: 1 }} />
      <Stack direction='row' justifyContent='end' p={1} mb={1} gap={1} flexWrap='wrap'>
        {actions &&
          actions.map((action) => (
            <Button key={action.key} {...action.buttonProps} onClick={() => onActionClick(action)}>
              {action.title}
            </Button>
          ))}
      </Stack>
    </>
  )
}

export default ModalFooter
