import { apiGet } from 'modules/api/predictor-api-client'
import { AxiosInstance } from 'axios'

const tournamentEndpoint = (tournamentId: string) => `/admin/tournament/${tournamentId}/game`
const gameEndpoint = (gameId?: string) => `/admin/game${gameId ? `/${gameId}` : ''}`

const getTournamentGames = async (client: AxiosInstance, tournamentId: string) => await apiGet(client, tournamentEndpoint(tournamentId))
const getGame = async (client: AxiosInstance, gameId: string) => await apiGet(client, gameEndpoint(gameId))

export { getTournamentGames, getGame }
