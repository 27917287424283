import {
  AppBar,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  Box,
  Hidden,
  ListItem,
} from '@mui/material'
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { useLocation, useNavigate } from 'react-router-dom'
import { APP_DETAILS, PREDICTOR_ROUTE_COLLECTION, STYLE_SETTINGS } from 'predictor-constants'
import MenuAuth from './MenuAuth'
import { usePredictorContext } from 'context/PredictorContext'
import { generateRouteWithId } from 'utils/route-utils'

const MainMenu: React.FC = () => {
  const [mainMenuOpen, setMainMenuOpen] = React.useState(false)
  const { activeTournament } = usePredictorContext()
  const navigate = useNavigate()
  const location = useLocation()

  const menuGroups = [
    [
      PREDICTOR_ROUTE_COLLECTION.tournaments,
      PREDICTOR_ROUTE_COLLECTION.users,
      PREDICTOR_ROUTE_COLLECTION.processes,
      PREDICTOR_ROUTE_COLLECTION.cache,
      PREDICTOR_ROUTE_COLLECTION.predictionInputLog,
    ],
  ]

  const tournamentMenuGroup = [
    PREDICTOR_ROUTE_COLLECTION.fixtures,
    PREDICTOR_ROUTE_COLLECTION.groups,
    PREDICTOR_ROUTE_COLLECTION.knockout,
    PREDICTOR_ROUTE_COLLECTION.games,
    PREDICTOR_ROUTE_COLLECTION.players,
    PREDICTOR_ROUTE_COLLECTION.validateScorers,
  ]

  const toggleMainMenu = (open: boolean) => {
    setMainMenuOpen(open)
  }

  const drawerContent: React.ReactElement = (
    <Box
      sx={{ width: STYLE_SETTINGS.DRAWER_WIDTH }}
      role='presentation'
      onClick={() => toggleMainMenu(false)}
      onKeyDown={() => toggleMainMenu(false)}
    >
      <AppBar position='static'>
        <Toolbar variant='dense' disableGutters sx={{ px: 1 }}>
          <Stack direction='row' justifyContent='left' alignItems='center' width='100%'>
            {APP_DETAILS.ICON}
            <Typography variant='h6' component='div' textAlign='left' sx={{ pl: 1 }}>
              {APP_DETAILS.TITLE}
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      <List sx={{ pt: 2 }}>
        {activeTournament && (
          <>
            <List
              sx={{
                p: 0,
              }}
            >
              <ListItem>
                <ListItemIcon sx={{ color: 'secondary' }}>{React.createElement(PREDICTOR_ROUTE_COLLECTION.tournaments.icon)}</ListItemIcon>
                <ListItemText sx={{ color: (theme) => theme.palette.grey[400] }}>{activeTournament.name}</ListItemText>
              </ListItem>
              {tournamentMenuGroup.map((menuItem) => (
                <ListItemButton
                  key={menuItem.path}
                  selected={location.pathname === menuItem.path}
                  onClick={() => navigate(generateRouteWithId(menuItem, activeTournament.id))}
                >
                  <ListItemIcon>{React.createElement(menuItem.icon, { color: 'secondary' })}</ListItemIcon>
                  <ListItemText primary={menuItem.title} />
                </ListItemButton>
              ))}
              <Divider sx={{ my: 2 }} />
            </List>
          </>
        )}

        {menuGroups.map((group, i) => (
          // Disabled because array is hardcoded. They will always have the same index.
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={i}>
            {group.map((menuItem) => (
              <ListItemButton key={menuItem.path} selected={location.pathname === menuItem.path} onClick={() => navigate(menuItem.path)}>
                <ListItemIcon>{React.createElement(menuItem.icon, { color: 'primary' })}</ListItemIcon>
                <ListItemText primary={menuItem.title} />
              </ListItemButton>
            ))}
            <Divider sx={{ my: 2 }} />
          </React.Fragment>
        ))}
        <MenuAuth />
      </List>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <MenuIcon onClick={() => toggleMainMenu(true)} />
        <Drawer
          anchor='left'
          ModalProps={{
            keepMounted: true,
          }}
          open={mainMenuOpen}
          onClose={() => toggleMainMenu(false)}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer anchor='left' variant='permanent'>
          {drawerContent}
        </Drawer>
      </Hidden>
    </>
  )
}

export default MainMenu
