import { PredictorRoute } from '../types/routing'

const getParsedTemplate = (template: string, value: string, value2?: string): string => {
  let result = `${template.replace(':{0}', value)}`
  if (value2) result = `${result.replace(':{1}', value2)}`
  return result
}

const generateRouteWithId = (route: PredictorRoute, id: string, id2?: string): string => getParsedTemplate(route.path, id, id2)

export { generateRouteWithId }
