import { apiDelete, apiGet, apiPost, apiPut } from 'modules/api/predictor-api-client'
import { AxiosInstance } from 'axios'
import { PlayerInsertRequest, ScorerNameUpdateRequest } from 'modules/api/types/admin'

const endpoint = (suffix?: string) => `/admin/player${suffix ? `/${suffix}` : ''}`

const getTournamentPlayers = async (client: AxiosInstance, tournamentId: string) =>
  await apiGet(client, `${endpoint()}?tournamentId=${tournamentId}`)
const postPlayer = async (client: AxiosInstance, tournamentId: string, player: PlayerInsertRequest) =>
  await apiPost(client, endpoint(), { ...player, tournamentId: tournamentId })
const deletePlayer = async (client: AxiosInstance, playerId: string) => await apiDelete(client, endpoint(playerId))
const validateScorers = async (client: AxiosInstance, tournamentId: string) => await apiPost(client, endpoint('validate'), { tournamentId })
const updateScorerName = async (client: AxiosInstance, tournamentId: string, request: ScorerNameUpdateRequest) =>
  await apiPut(client, endpoint('scorerName'), { ...request, tournamentId })

export { getTournamentPlayers, postPlayer, deletePlayer, validateScorers, updateScorerName }
