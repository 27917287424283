import { Alert, Button, ButtonTypeMap, Tooltip, Typography } from '@mui/material'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'
import { usePredictorApi } from 'modules/api'
import { submitFixtureUpdate } from 'modules/api/requests/admin'
import { useConfirmationModalContext } from 'modules/ui'
import React from 'react'
import { isSuccessStatusCode } from 'utils/request-utils'

interface IProps {
  fixtureId: string
  onFixtureUpdateSubmitted?: () => void
  buttonProps?: DefaultComponentProps<ButtonTypeMap>
}

const SubmitFixtureUpdateButton: React.FC<IProps> = ({ fixtureId, onFixtureUpdateSubmitted, buttonProps }) => {
  const apiUpdateScore = usePredictorApi({
    successMessage: 'Fixture update submitted. Now running post update process.',
    errorMessage: 'Error submitting update.',
  })

  const { showConfirm } = useConfirmationModalContext()

  const onSubmit = async () => {
    const confirmed = await showConfirm({
      title: 'Submit fixture update',
      confirmText: 'Start process',
      renderMessage: () => (
        <>
          This will start the post fixture update process which will update team points, participant points and standings.
          <Alert severity='warning' sx={{ my: 2 }}>
            <Typography>
              Part of the process calculates the new group standings. Fixtures which have statuses other than NotStarted or Other will be
              taken into consideration, even if the submit button was not clicked for those fixtures.
            </Typography>
          </Alert>
          {/* <Stack direction='row' alignItems='center'>
            <WarningRounded />
          </Stack> */}
          Are you sure you want to continue?
        </>
      ),
    })

    if (!confirmed) return

    const response = await apiUpdateScore.requestWithParams((client) => submitFixtureUpdate(client, fixtureId))
    if (isSuccessStatusCode(response) && onFixtureUpdateSubmitted) onFixtureUpdateSubmitted()
  }

  return (
    <Tooltip title='This will update the score of this fixture according to the goals assigned to it in normal time.'>
      <Button {...buttonProps} onClick={onSubmit}>
        Submit fixture update
      </Button>
    </Tooltip>
  )
}

export default SubmitFixtureUpdateButton
