import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { TournamentTypes } from 'modules/api/types/admin'
import React, { useEffect } from 'react'
import { FormFieldProps } from 'types/form'

interface IProps extends FormFieldProps {
  tournamentTypes?: TournamentTypes
}

const TournamentTypeField: React.FC<IProps> = ({ field, onChange, tournamentTypes }) => {
  useEffect(() => {
    if (!tournamentTypes || Object.keys(tournamentTypes).length === 0 || field.value) return

    onChange(Object.keys(tournamentTypes)[0])
  }, [tournamentTypes])

  return (
    <FormControl error={!field.isValid}>
      <InputLabel id='tournament-type-label'>Tournament Type</InputLabel>
      <Select
        labelId='tournament-type-label'
        id='tournament-type'
        value={field.value}
        label='Tournament Type'
        onChange={(e) => onChange(e.target.value)}
      >
        {tournamentTypes &&
          Object.keys(tournamentTypes).map((type) => (
            <MenuItem key={type} value={type}>
              {tournamentTypes[type]}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export default TournamentTypeField
