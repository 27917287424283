import { FormFieldValidationFunction, FormFieldValidationOptions } from './form'
import { parse as parseDate, isValid as isValidDate } from 'date-fns'

const getFieldName = (validationOptions: FormFieldValidationOptions) => {
  if (validationOptions && validationOptions.fieldDisplayName) return validationOptions.fieldDisplayName
  return 'Field'
}

const getMessage = (messageTemplate: string, options: FormFieldValidationOptions) => {
  return options.message ?? messageTemplate.replaceAll('{{field}}', getFieldName(options))
}

const validateRequired: FormFieldValidationFunction = (value, options) => {
  if (value === undefined || value === null || value === '') return [getMessage('{{field}} is required', options)]
  return []
}

const validatePositiveInteger: FormFieldValidationFunction = (value, options) => {
  if (value < 0) return [getMessage('{{field}} must be a whole number greater than 0.', options)]
  return []
}

const validateDateString: FormFieldValidationFunction = (value, options) => {
  const { format } = options

  if (isValidDate(parseDate(value as string, format as string, new Date()))) return []

  return [getMessage(`{{field}} must be in the following format: ${format}`, options)]
}

export { validateRequired, validatePositiveInteger, validateDateString }
