import { Fixture } from 'modules/api/types/admin'
import { Modal, ModalOptions } from 'modules/ui'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { generateRouteWithId } from 'utils/route-utils'
import { getFixtureDescription } from '../utils/fixture-utils'
import FixtureDetails from '../components/FixtureDetails'

interface IProps {
  fixture: Fixture
}

const FixtureDetailsModal: React.FC<IProps> = ({ fixture }) => {
  const navigate = useNavigate()

  const options: ModalOptions = {
    title: `${getFixtureDescription(fixture, false, true)}`,
    renderContent: () => <FixtureDetails fixture={fixture} />,
    actions: [
      {
        key: 'edit-status',
        title: 'Edit Status',
        onActionClick: () => navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.editFixtureStatus, fixture.id)),
        buttonProps: { variant: 'contained', endIcon: React.createElement(PREDICTOR_ROUTE_COLLECTION.editFixtureStatus.icon) },
      },
      {
        key: 'edit-general-details',
        title: 'Edit Date',
        onActionClick: () => navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.editFixtureGeneralDetails, fixture.id)),
        buttonProps: { variant: 'outlined', endIcon: React.createElement(PREDICTOR_ROUTE_COLLECTION.editFixtureGeneralDetails.icon) },
      },
    ],
  }

  return <Modal open={true} onClose={() => navigate(-1)} {...options} />
}

export default FixtureDetailsModal
