import { RefreshRounded } from '@mui/icons-material'
import { Alert, Container, Fade, Hidden, IconButton, Stack, Theme, useMediaQuery } from '@mui/material'
import { usePredictorApi } from 'modules/api'
import { getTournament } from 'modules/api/requests/admin'
import { getTournamentGroups } from 'modules/api/requests/tournament-requests'
import { Tournament } from 'modules/api/types/admin'
import { TournamentGroups } from 'modules/api/types/tournament'
import { LoadingBox, PageNotFound, PageTitle } from 'components'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { useParams } from 'react-router-dom'
import { GroupView } from '../types/group'
import { Group } from '../components'

const Groups = () => {
  const { tournamentId } = useParams()
  if (tournamentId === undefined) return <PageNotFound />

  const getTournamentApi = usePredictorApi<Tournament>({ apiFunc: (client) => getTournament(client, tournamentId) })
  const tournament = getTournamentApi.data

  const getGroupsApi = usePredictorApi<TournamentGroups>({ apiFunc: (client) => getTournamentGroups(client, tournamentId) })
  const tournamentGroups = getGroupsApi.data
  const groups: GroupView[] =
    tournamentGroups === undefined
      ? []
      : Object.keys(tournamentGroups).map((groupName) => ({
          groupName,
          teams: tournamentGroups[groupName],
          positionOverride: tournamentGroups[groupName].some((t) => Boolean(t.positionOverride)),
          positionsConfirmed: tournamentGroups[groupName].every((t) => t.positionConfirmed),
        }))

  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  return (
    <>
      {tournament && (
        <PageTitle
          title={
            <>
              {tournament?.name}
              <Hidden smDown> - {PREDICTOR_ROUTE_COLLECTION.groups.title}</Hidden>{' '}
              <Hidden smDown>
                <IconButton onClick={() => getGroupsApi.request()}>
                  <RefreshRounded className={getGroupsApi.isLoading ? 'spin' : ''} />
                </IconButton>
              </Hidden>
            </>
          }
          icon={PREDICTOR_ROUTE_COLLECTION.groups.icon}
        />
      )}

      {(getTournamentApi.isLoading || (getGroupsApi.isLoading && !groups)) && <LoadingBox />}

      {(getTournamentApi.error || getGroupsApi.error) && (
        <Alert severity='error'>Error retrieving data. {getTournamentApi.error || getGroupsApi.error}</Alert>
      )}

      {getTournamentApi.data && getGroupsApi.data && (
        <Container maxWidth='md' disableGutters={!isMdUp}>
          <Fade in={true} timeout={1000}>
            <Stack gap={3}>
              {groups && (
                <>
                  {groups.map((group) => (
                    <Group key={group.groupName} group={group} tournamentId={tournamentId} onGroupUpdate={() => getGroupsApi.request()} />
                  ))}
                </>
              )}
            </Stack>
          </Fade>
        </Container>
      )}
    </>
  )
}

export default Groups
