import { TextField } from '@mui/material'
import React from 'react'
import { FormFieldProps } from 'types/form'

const GoalTimeField: React.FC<FormFieldProps> = ({ field, onChange }) => {
  return (
    <TextField
      id='goal-time'
      label='Time'
      required
      error={!field.isValid}
      helperText={field.isValid ? 'The goal time. e.g. 20 or 90+3' : field.validationErrors[0]}
      value={field.value}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export default GoalTimeField
