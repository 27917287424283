import { Grid, Hidden, Stack, Theme, Typography, useMediaQuery } from '@mui/material'
import { Fixture, Goal } from 'modules/api/types/admin'
import { Link, TeamName } from 'components'
import { format as formatDate } from 'date-fns'
import React, { ReactElement } from 'react'
import GoalDisplay from './GoalDisplay'

interface IProps {
  fixture: Fixture
  showScorers?: boolean
  onScoreClick?: () => void
  compact?: boolean
}
const MatchWithGoals: React.FC<IProps> = ({ fixture, showScorers, onScoreClick, compact = false }) => {
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const viewableGoals = fixture.goals.filter((g) => g.type !== 'PenaltyInPenaltyShootout')
  const homeGoalList = viewableGoals.filter((g) => g.team === 'Home')
  const awayGoalList = viewableGoals.filter((g) => g.team === 'Away')

  const renderGoals = (goalList?: Goal[]): React.ReactElement => (
    <>
      {goalList && goalList.length > 0 && (
        <Stack>
          {goalList.map((goal) => (
            <GoalDisplay key={goal.id} goal={goal} />
          ))}
        </Stack>
      )}
    </>
  )

  const fixtureScore = (): ReactElement => (
    <>
      {fixture.fixtureStatus === 'NotStarted'
        ? formatDate(new Date(fixture.dateTime), 'HH:mm')
        : `${fixture.homeTeamScore} - ${fixture.awayTeamScore}`}
    </>
  )

  const renderGoalsList = (goalList?: Goal[]): React.ReactElement => {
    if (showScorers === undefined) return <Hidden mdDown>{renderGoals(goalList)}</Hidden>
    if (showScorers) return renderGoals(goalList)
    return <></>
  }

  const showScoreLink = onScoreClick && fixture.fixtureStatus !== 'NotStarted'

  const match = (
    <Grid container columns={24} columnSpacing={1}>
      <Grid item xs={9} sm={10} md={compact ? 10 : 11}>
        <Stack alignItems='flex-end'>
          {fixture.homeTeam && <TeamName team={fixture.homeTeam} showFullName={isSmUp} flag='end' />}
          {!fixture.homeTeam && fixture.homeTeamPlaceholder}
          {renderGoalsList(homeGoalList)}
        </Stack>
      </Grid>
      <Grid item xs={6} sm={4} md={compact ? 4 : 2}>
        <>
          {showScoreLink && (
            <Link onClick={onScoreClick} align='center'>
              {fixtureScore()}
            </Link>
          )}
          {!showScoreLink && <Typography textAlign='center'>{fixtureScore()}</Typography>}
          {fixture.outcomeDescription && (
            <Typography component='div' width='100%' textAlign='center' variant='caption'>
              ({fixture.outcomeDescription})
            </Typography>
          )}
        </>
      </Grid>
      <Grid item xs={9} sm={10} md={compact ? 10 : 11}>
        <Stack>
          {fixture.awayTeam && <TeamName team={fixture.awayTeam} showFullName={isSmUp} />}
          {!fixture.awayTeam && fixture.awayTeamPlaceholder}
          {renderGoalsList(awayGoalList)}
        </Stack>
      </Grid>
    </Grid>
  )

  return match
}

export default MatchWithGoals
