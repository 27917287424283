import { CircularProgress, Stack, SxProps, Theme } from '@mui/material'
import React from 'react'

const LoadingBox: React.FC = () => {
  const sx: SxProps<Theme> = {
    color: (theme: Theme) => theme.palette.primary.main,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  }

  return (
    <Stack sx={sx}>
      <CircularProgress color='inherit' />
    </Stack>
  )
}

export default LoadingBox
