import { Alert, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Fixture } from 'modules/api/types/admin'
import { TeamName } from 'components'
import React from 'react'
import { FormFieldProps } from 'types/form'

interface IProps extends FormFieldProps {
  fixture: Fixture
}

const GoalTeamField: React.FC<IProps> = ({ field, onChange, fixture }) => {
  if (!fixture.homeTeam || !fixture.awayTeam) return <Alert severity='warning'>Cannot add goals to fixtures with undetermined teams.</Alert>

  return (
    <FormControl error={!field.isValid}>
      <InputLabel id='fixture-team-label'>Team</InputLabel>
      <Select labelId='fixture-team-label' id='fixture-team' value={field.value} label='Team' onChange={(e) => onChange(e.target.value)}>
        <MenuItem value={'Home'}>
          <TeamName team={fixture.homeTeam} />
        </MenuItem>
        <MenuItem value={'Away'}>
          <TeamName team={fixture.awayTeam} />
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default GoalTeamField
