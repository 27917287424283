import { RefreshRounded } from '@mui/icons-material'
import { Alert, Container, Hidden, IconButton } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Link, LoadingBox, PageNotFound, PageTitle } from 'components'
import { usePredictorApi } from 'modules/api'
import { getTournament, getTournamentGames } from 'modules/api/requests/admin'
import { Game, Tournament } from 'modules/api/types/admin'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DataGridState } from 'types/data-grid'
import { generateRouteWithId } from 'utils/route-utils'

interface GameView extends Game {
  participantCount: number
  gameAdminEmail: string
}

const Games = () => {
  const { tournamentId } = useParams()
  const navigate = useNavigate()
  if (tournamentId === undefined) return <PageNotFound />

  const getTournamentApi = usePredictorApi<Tournament>({ apiFunc: (client) => getTournament(client, tournamentId) })
  const tournament = getTournamentApi.data

  const getGamesApi = usePredictorApi<Game[]>({ apiFunc: (client) => getTournamentGames(client, tournamentId) })
  const tournamentGames = (getGamesApi.data?.map((x) => ({
    ...x,
    participantCount: x.participants.length,
    gameAdminEmail: x.participants.find((p) => p.isGameAdmin)?.user?.email,
  })) ?? []) as GameView[]

  const [dataGridState, setDataGridState] = useState<DataGridState>({ pageSize: 10, page: 0 })

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 200 },
    {
      field: 'participantCount',
      headerName: 'Participants',
      width: 100,
      align: 'right',
      renderCell: (params) => (
        <Link
          sx={{ justifyContent: 'end' }}
          typography={{ width: '100% !important', textAlign: 'right' }}
          onClick={() => navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.gameStandings, params.row.tournamentId, params.row.id))}
        >
          {params.row.participantCount}
        </Link>
      ),
    },
    { field: 'gameAdminEmail', headerName: 'Admin', width: 200 },
    { field: 'id', headerName: 'ID', width: 300, sortable: false },
  ]

  return (
    <>
      {tournament && (
        <PageTitle
          title={
            <>
              {tournament?.name}
              <Hidden smDown> - {PREDICTOR_ROUTE_COLLECTION.games.title}</Hidden>{' '}
              <Hidden smDown>
                <IconButton onClick={() => getGamesApi.request()}>
                  <RefreshRounded className={getGamesApi.isLoading ? 'spin' : ''} />
                </IconButton>
              </Hidden>
            </>
          }
          icon={PREDICTOR_ROUTE_COLLECTION.games.icon}
        />
      )}

      {(getTournamentApi.isLoading || (getGamesApi.isLoading && !tournamentGames)) && <LoadingBox />}

      {(getTournamentApi.error || getGamesApi.error) && (
        <Alert severity='error'>Error retrieving data. {getTournamentApi.error || getGamesApi.error}</Alert>
      )}

      {getTournamentApi.data && getGamesApi.data && (
        <Container>
          <DataGrid
            rows={tournamentGames}
            columns={columns}
            autoHeight
            disableSelectionOnClick={true}
            rowsPerPageOptions={[10, 20, 50]}
            pageSize={dataGridState.pageSize}
            page={dataGridState.page}
            filterModel={dataGridState.filter}
            sortModel={dataGridState.sort}
            onPageSizeChange={(pageSize) => setDataGridState({ ...dataGridState, pageSize: pageSize })}
            onPageChange={(page) => setDataGridState({ ...dataGridState, page: page })}
            onFilterModelChange={(filter) => setDataGridState({ ...dataGridState, filter: filter })}
            onSortModelChange={(sort) => setDataGridState({ ...dataGridState, sort: sort })}
          />
        </Container>
      )}
    </>
  )
}

export default Games
