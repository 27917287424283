import { Circle } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { Process } from 'modules/api/types/admin'
import React, { ReactElement } from 'react'
import { format as formatDate } from 'date-fns'
import { DISPLAY_DATE_FORMAT_S } from 'predictor-constants'
import { renderProcessStatus } from '../utils/process-utils'

interface IProps {
  process: Process
}

const ProcessDetails: React.FC<IProps> = ({ process }) => {
  const row = (field: string, value: string | number | ReactElement) => ({ field, value })
  const rows: { field: string; value: string | number | ReactElement }[] = [
    row('Name', process.name),
    row('Context', process.processContext),
    row('Status', renderProcessStatus(process)),
    row('Error', process.errorMessage || 'N/A'),
    row('Run Time', `${process.runTimeInMs} ms`),
    row('Date Added', formatDate(new Date(process.utcAddedTime), DISPLAY_DATE_FORMAT_S)),
    row('Date Started', formatDate(new Date(process.utcStartTime), DISPLAY_DATE_FORMAT_S)),
    row('Date Ended', formatDate(new Date(process.utcEndTime), DISPLAY_DATE_FORMAT_S)),
    row('Background', process.runInBackground ? 'Yes' : 'No'),
    row('Chain', process.chainId),
    row('Caller', process.callerId),
    row('ID', process.id),
  ]
  return (
    <Box display='grid' gridTemplateColumns='auto 10px 1fr' gap={2} px={3} pb={2}>
      {rows.map((row) => (
        <React.Fragment key={row.field}>
          <Typography component='div'>
            <strong>{row.field}</strong>
          </Typography>
          <div style={{ fontSize: 8, marginTop: '7px' }}>
            <Circle fontSize='inherit' sx={{ color: 'emphasis' }} />
          </div>
          <Typography component='div'>{row.value}</Typography>
        </React.Fragment>
      ))}
    </Box>
  )
}

export default ProcessDetails
