import { AddCircle, Restore } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import { usePredictorApi } from 'modules/api'
import { postPlayer } from 'modules/api/requests/admin'
import { Player, PlayerInsertRequest, Tournament } from 'modules/api/types/admin'
import { useForm } from 'modules/form'
import React, { FormEvent } from 'react'
import { validateRequired } from 'types/form'
import { isSuccessStatusCode } from 'utils/request-utils'
import PlayerNameField from './PlayerNameField'
import PlayerTeamField from './PlayerTeamField'

interface IProps {
  tournament: Tournament
  onPlayerUpdated?: () => void
}

const PlayerForm: React.FC<IProps> = ({ tournament, onPlayerUpdated }) => {
  const apiPostPlayer = usePredictorApi<Player>()

  const form = useForm({
    teamCode: { validations: [validateRequired] },
    playerName: { validations: [validateRequired] },
  })

  const { teamCode, playerName } = form.fields

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const request: PlayerInsertRequest = {
      teamCode: teamCode.value as string,
      playerName: playerName.value as string,
    }
    const response = await apiPostPlayer.requestWithParams((client) => postPlayer(client, tournament.id, request))
    if (isSuccessStatusCode(response)) {
      if (onPlayerUpdated) onPlayerUpdated()
      form.reset()
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={2}>
        <PlayerTeamField
          field={teamCode}
          onChange={form.set.teamCode}
          teams={tournament.teams.sort((a, b) => a.name.localeCompare(b.name))}
        />
        <PlayerNameField field={playerName} onChange={form.set.playerName} />
        <Button variant='contained' type='submit' disabled={!form.isValid} endIcon={<AddCircle />}>
          Add player
        </Button>
        <Button variant='outlined' endIcon={<Restore />} onClick={() => form.reset()}>
          Reset
        </Button>
      </Stack>
    </form>
  )
}

export default PlayerForm
