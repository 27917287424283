import { TextField } from '@mui/material'
import { INPUT_DATE_FORMAT } from 'predictor-constants'
import React from 'react'
import { FormFieldProps } from 'types/form'

const TournamentStartDateField: React.FC<FormFieldProps> = ({ field, onChange }) => {
  const defaultHelperText = () => (
    <>
      The start date/time of the tournament in
      <strong> local time </strong>, when tournament specific predictions are closed. Use format <strong>{INPUT_DATE_FORMAT}</strong>
    </>
  )

  return (
    <TextField
      id='start-date'
      label='Start Date'
      variant='standard'
      error={!field.isValid}
      helperText={field.isValid ? defaultHelperText() : field.validationErrors[0]}
      required
      value={field.value}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export default TournamentStartDateField
