import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { FixtureStatusOption } from 'modules/api/types/admin'
import { getFixtureStatusIconFromStatus } from 'modules/fixtures/utils/fixture-utils'
import React from 'react'
import { FormFieldProps } from 'types/form'

const FixtureStatusField: React.FC<FormFieldProps> = ({ field, onChange }) => {
  const statusOptions: { status: FixtureStatusOption; displayName: string }[] = [
    {
      status: 'NotStarted',
      displayName: 'Not Started',
    },
    {
      status: 'InProgress',
      displayName: 'In Progress',
    },
    {
      status: 'FirstHalf',
      displayName: 'First Half',
    },
    {
      status: 'SecondHalf',
      displayName: 'Second Half',
    },
    {
      status: 'ExtraTime',
      displayName: 'Extra Time',
    },
    {
      status: 'Penalties',
      displayName: 'Penalties',
    },
    {
      status: 'FullTime',
      displayName: 'Full Time',
    },
    {
      status: 'Other',
      displayName: 'Other',
    },
  ]

  return (
    <FormControl error={!field.isValid}>
      <InputLabel id='fixture-status-label'>Fixture Status</InputLabel>
      <Select
        labelId='fixture-status-label'
        id='fixture-status'
        value={field.value}
        label='Fixture Status'
        onChange={(e) => onChange(e.target.value)}
        renderValue={(value) => {
          const optionSelected = statusOptions.find((x) => x.status === value)
          if (!optionSelected) return <></>
          return (
            <Stack direction='row' alignItems='center' gap={1}>
              {getFixtureStatusIconFromStatus(optionSelected.status)} {optionSelected.displayName}
            </Stack>
          )
        }}
      >
        {statusOptions.map((option) => (
          <MenuItem key={option.status} value={option.status} sx={{ gap: 1 }}>
            {getFixtureStatusIconFromStatus(option.status)} {option.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default FixtureStatusField
