import { Alert, Button, Container, Divider, Grid, Hidden, Paper, Stack, Typography } from '@mui/material'
import { usePredictorApi } from 'modules/api'
import { getFixture, patchFixture } from 'modules/api/requests/admin'
import { useForm } from 'modules/form'
import { FormEvent, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { validatePositiveInteger, validateRequired } from 'types/form'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { Fixture, FixturePatchRequest, FixtureStatusOption } from 'modules/api/types/admin'
import { PageTitle, Link } from 'components'
import { ArrowBack, Publish, Save, Sync } from '@mui/icons-material'
import { getFixtureDescription } from '../utils/fixture-utils'
import { generateRouteWithId } from 'utils/route-utils'
import { FixtureStatusField, OutcomeDescriptionField, ScoreField } from '../components/fields'
import { EditFixtureGoals, SubmitFixtureUpdateButton, UpdateFixtureScoreFromGoalsButton } from '../components'

const EditFixtureStatus = () => {
  const navigate = useNavigate()

  const { fixtureId } = useParams()

  if (!fixtureId) return <Alert severity='error'>Fixture ID not specified</Alert>

  const apiGetFixture = usePredictorApi<Fixture>({
    apiFunc: (client) => getFixture(client, fixtureId),
    errorMessage: 'Could not get fixture.',
  })
  const fixture = apiGetFixture.data

  const apiPatchFixture = usePredictorApi({
    errorMessage: 'Could not update fixture. {{error}}',
    successMessage: 'Fixture updated.',
  })

  const form = useForm({
    fixtureStatus: { defaultValue: 'NotStarted' },
    homeTeamScore: {
      defaultValue: 0,
      validations: [
        validateRequired,
        (value, options) => validatePositiveInteger(value, { ...options, fieldDisplayName: 'Home Team Score' }),
      ],
    },
    awayTeamScore: {
      defaultValue: 0,
      validations: [validateRequired, validatePositiveInteger],
    },
    homeTeamScoreAET: { validations: [validatePositiveInteger] },
    awayTeamScoreAET: { validations: [validatePositiveInteger] },
    outcomeDescription: {},
  })

  const { fixtureStatus, homeTeamScore, awayTeamScore, outcomeDescription } = form.fields

  const onEditStatusSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const patchRequest: FixturePatchRequest = {
      fixtureStatus: fixtureStatus.value as FixtureStatusOption,
      homeTeamScore: homeTeamScore.value as number,
      awayTeamScore: awayTeamScore.value as number,
      outcomeDescription: outcomeDescription.value as string,
    }
    await apiPatchFixture.requestWithParams((client) => patchFixture(client, fixtureId, patchRequest))
  }

  useEffect(() => {
    if (!fixture) return
    form.set.fixtureStatus(fixture.fixtureStatus)
    form.set.homeTeamScore(fixture.homeTeamScore)
    form.set.awayTeamScore(fixture.awayTeamScore)
    if (fixture.outcomeDescription) form.set.outcomeDescription(fixture.outcomeDescription)
  }, [fixture])

  const renderForm = () => {
    if (!fixture) return <></>

    return (
      <form onSubmit={onEditStatusSubmit}>
        <Typography variant='h4'>Status</Typography>
        <Divider sx={{ mb: 4 }} />
        <Stack gap={3}>
          <FixtureStatusField field={fixtureStatus} onChange={form.set.fixtureStatus} />

          {fixture.homeTeam && fixture.awayTeam && (
            <>
              <ScoreField field={homeTeamScore} onChange={form.set.homeTeamScore} team={fixture.homeTeam} />
              <ScoreField field={awayTeamScore} onChange={form.set.awayTeamScore} team={fixture.awayTeam} />
            </>
          )}
          <OutcomeDescriptionField field={outcomeDescription} onChange={form.set.outcomeDescription} />

          <Button variant='contained' disabled={!form.isValid} type='submit' fullWidth={true} endIcon={<Save />}>
            Save
          </Button>
          <UpdateFixtureScoreFromGoalsButton
            buttonProps={{ variant: 'outlined', endIcon: <Sync /> }}
            fixtureId={fixture.id}
            onScoreUpdated={() => apiGetFixture.request()}
          />
          <SubmitFixtureUpdateButton
            buttonProps={{ variant: 'outlined', color: 'secondary', endIcon: <Publish /> }}
            fixtureId={fixture.id}
          />
        </Stack>
      </form>
    )
  }

  return (
    <>
      <PageTitle
        title={
          <>
            <Hidden smDown>Edit Fixture Outcome - </Hidden>
            {fixture && <strong>{getFixtureDescription(fixture, false, true)}</strong>}
          </>
        }
        icon={PREDICTOR_ROUTE_COLLECTION.editFixtureStatus.icon}
      />
      {fixture && (
        <Container maxWidth='md' sx={{ mt: 2 }}>
          <Alert severity='warning' sx={{ mb: 3 }}>
            <Stack direction='row' alignItems='center' gap={1} flexWrap='wrap'>
              <Typography component='div'>After adding a goal, you can update the score by clicking</Typography>
              <Typography component='div' color='warning' sx={{ fontStyle: 'italic', display: 'inline-flex', alignItems: 'center' }}>
                <Sync fontSize='small' />
                <strong>Update score from goals</strong>
              </Typography>
            </Stack>
          </Alert>
          <Grid container columnSpacing={3} rowSpacing={3}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3 }}>{renderForm()}</Paper>
              {fixture && (
                <Link
                  sx={{ mt: 3 }}
                  icon={<ArrowBack />}
                  onClick={() => navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.fixtures, fixture?.tournamentId))}
                >
                  Back to fixtures
                </Link>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3 }}>
                <Typography variant='h4'>Goals</Typography>
                <Divider sx={{ mb: 4 }} />
                <EditFixtureGoals fixture={fixture} />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  )
}

export default EditFixtureStatus
