import {
  AccessTime,
  AccountTree,
  ArrowRight,
  AutoStories,
  Casino,
  EmojiEvents,
  FactCheck,
  FormatListNumbered,
  Man,
  Memory,
  People,
  Scoreboard,
  Sports,
  Storage,
  TableRows,
} from '@mui/icons-material'
import { EditFixtureGeneralDetails, EditFixtureStatus, FixtureDetailsModal, FixtureList } from 'modules/fixtures'
import { LandingPage } from 'pages'
import { ProcessList } from 'modules/processes'
import { Groups, Knockout } from 'modules/teams'
import { EditTournament, NewTournament, TournamentList } from 'modules/tournaments'
import { UserList } from 'modules/users'
import { Route, Routes, Location } from 'react-router-dom'
import { APP_DETAILS } from '.'
import { ModalLocationState, PredictorRouteCollection } from '../types/routing'
import { Games } from 'modules/games'
import { Fixture } from 'modules/api/types/admin'
import GameStandings from 'modules/games/pages/GameStandings'
import { Cache } from 'modules/cache'
import { PredictionInputLog } from 'modules/predictionInputLog'
import { Players, ValidateScorers } from 'modules/players'

const PREDICTOR_ROUTE_COLLECTION: PredictorRouteCollection = {
  home: {
    path: '/',
    title: APP_DETAILS.TITLE,
    icon: ArrowRight,
  },
  tournaments: {
    path: '/tournaments',
    title: 'Tournaments',
    icon: EmojiEvents,
  },
  newTournament: {
    path: '/tournaments/new',
    title: 'New Tournament',
    icon: EmojiEvents,
  },
  editTournament: {
    path: '/tournaments/:{0}',
    pathForRoute: '/tournaments/:tournamentId',
    title: 'Edit Tournament',
    icon: EmojiEvents,
  },
  fixtures: {
    path: '/tournaments/:{0}/fixtures',
    pathForRoute: '/tournaments/:tournamentId/fixtures',
    title: 'Fixtures',
    icon: Sports,
  },
  editFixtureGeneralDetails: {
    path: '/fixtures/:{0}/edit',
    pathForRoute: '/fixtures/:fixtureId/edit',
    title: 'Edit Fixture Details',
    icon: AccessTime,
  },
  editFixtureStatus: {
    path: '/fixtures/:{0}/status',
    pathForRoute: '/fixtures/:fixtureId/status',
    title: 'Edit Fixture Status',
    icon: Scoreboard,
  },
  groups: {
    path: '/tournaments/:{0}/groups',
    pathForRoute: '/tournaments/:tournamentId/groups',
    title: 'Groups',
    icon: TableRows,
  },
  knockout: {
    path: '/tournaments/:{0}/knockout',
    pathForRoute: '/tournaments/:tournamentId/knockout',
    title: 'Knockout',
    icon: AccountTree,
  },
  games: {
    path: '/tournaments/:{0}/games',
    pathForRoute: '/tournaments/:tournamentId/games',
    title: 'Games',
    icon: Casino,
  },
  gameStandings: {
    path: '/tournaments/:{0}/games/:{1}/standings',
    pathForRoute: 'tournaments/:tournamentId/games/:gameId/standings',
    title: 'Game Standings',
    icon: FormatListNumbered,
  },
  players: {
    path: '/tournaments/:{0}/players',
    pathForRoute: '/tournaments/:tournamentId/players',
    title: 'Players',
    icon: Man,
  },
  validateScorers: {
    path: '/tournaments/:{0}/validateScorers',
    pathForRoute: '/tournaments/:tournamentId/validateScorers',
    title: 'Validate Scorers',
    icon: FactCheck,
  },
  users: {
    path: '/users',
    title: 'Users',
    icon: People,
  },
  processes: {
    path: '/processes',
    title: 'Processes',
    icon: Memory,
  },
  cache: {
    path: '/cache',
    title: 'Cache',
    icon: Storage,
  },
  predictionInputLog: {
    path: '/predictionInputLog',
    title: 'Prediction Input Log',
    icon: AutoStories,
  },
}

export const setupRoutes = (location: Location) => {
  const modalLocationState = location.state as ModalLocationState | undefined
  return (
    <>
      <Routes location={modalLocationState?.backgroundLocation || location}>
        <Route path={PREDICTOR_ROUTE_COLLECTION.home.path}>
          <Route index element={<LandingPage />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.tournaments.path} element={<TournamentList />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.newTournament.path} element={<NewTournament />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.editTournament.pathForRoute} element={<EditTournament />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.fixtures.pathForRoute} element={<FixtureList />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.editFixtureGeneralDetails.pathForRoute} element={<EditFixtureGeneralDetails />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.editFixtureStatus.pathForRoute} element={<EditFixtureStatus />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.groups.pathForRoute} element={<Groups />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.knockout.pathForRoute} element={<Knockout />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.games.pathForRoute} element={<Games />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.gameStandings.pathForRoute} element={<GameStandings />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.players.pathForRoute} element={<Players />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.validateScorers.pathForRoute} element={<ValidateScorers />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.users.path} element={<UserList />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.processes.path} element={<ProcessList />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.cache.path} element={<Cache />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.predictionInputLog.path} element={<PredictionInputLog />} />
        </Route>
      </Routes>

      {modalLocationState?.backgroundLocation && (
        <Routes>
          <Route path='/fixture/:fixtureId' element={<FixtureDetailsModal fixture={modalLocationState.props as Fixture} />} />
        </Routes>
      )}
    </>
  )
}

export default PREDICTOR_ROUTE_COLLECTION
