import { Stack } from '@mui/material'
import { Fixture } from 'modules/api/types/admin'
import React from 'react'
import { getFixtureStatusIcon, renderFixtureStatusText } from '../utils/fixture-utils'
import MatchWithGoals from './MatchWithGoals'

interface IProps {
  fixture: Fixture
}

const FixtureDetails: React.FC<IProps> = ({ fixture }) => {
  return (
    <Stack gap={2}>
      <Stack direction='row' alignItems='center' justifyContent='center' gap={1}>
        {getFixtureStatusIcon(fixture)}
        {renderFixtureStatusText(fixture)}
      </Stack>
      <MatchWithGoals fixture={fixture} showScorers={true} compact={true} />
    </Stack>
  )
}

export default FixtureDetails
