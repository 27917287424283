import { Circle, Event } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Fixture, FixtureStatusOption } from 'modules/api/types/admin'
import { format as formatDate } from 'date-fns'
import React, { ReactElement } from 'react'
import { getStageDisplayName, getStageFromCode } from 'utils/stage-utils'

const getFixtureStatusIconFromStatus = (status: FixtureStatusOption): React.ReactElement => {
  switch (status) {
    case 'NotStarted':
      return <Event />
    case 'InProgress':
    case 'FirstHalf':
    case 'SecondHalf':
    case 'ExtraTime':
    case 'Penalties':
      return <Circle color='primary' className='blink' />
    case 'FullTime':
      return <Circle />
    default:
      return <Circle color='disabled' />
  }
}

const getFixtureStatusIcon = (fixture: Fixture): React.ReactElement => getFixtureStatusIconFromStatus(fixture.fixtureStatus)

const getFixtureStatusText = (fixture: Fixture): string => {
  switch (fixture.fixtureStatus) {
    case 'NotStarted':
      return formatDate(new Date(fixture.dateTime), 'dd MMM')
    case 'InProgress':
      return 'In progress'
    case 'FirstHalf':
      return 'First half'
    case 'SecondHalf':
      return 'Second half'
    case 'ExtraTime':
      return 'Extra time'
    case 'Penalties':
      return 'Penalties'
    case 'FullTime':
      return 'Full time'
    default:
      return ''
  }
}

const renderFixtureStatusText = (fixture: Fixture): ReactElement => {
  switch (fixture.fixtureStatus) {
    case 'NotStarted':
      return <Typography variant='subtitle2'>{getFixtureStatusText(fixture)}</Typography>
    case 'FullTime':
      return <Typography variant='subtitle2'>{getFixtureStatusText(fixture)}</Typography>
    default:
      return (
        <Typography color='primary' variant='subtitle2'>
          {getFixtureStatusText(fixture)}
        </Typography>
      )
  }
}

const getShortScorerName = (scorerName: string) => {
  const lastSpaceIndex = scorerName.lastIndexOf(' ')
  const lastName = lastSpaceIndex >= 0 ? scorerName.substring(lastSpaceIndex + 1) : scorerName
  return lastName
}

const getFixtureDescription = (fixture: Fixture, shortVersion = false, withIdentifier = false): string => {
  const stageName = getStageDisplayName(fixture.code, shortVersion)
  if (!withIdentifier) return stageName

  const stage = getStageFromCode(fixture.code)
  return `${stageName} Match ${stage?.identifier}`
}

export {
  getFixtureStatusIcon,
  getFixtureStatusIconFromStatus,
  getFixtureStatusText,
  renderFixtureStatusText,
  getFixtureDescription,
  getShortScorerName,
}
