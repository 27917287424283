import { AppBar, Divider, Hidden, IconButton, Stack, Toolbar } from '@mui/material'
import React from 'react'
import { UserAvatar } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import MainMenu from '../main-menu/MainMenu'
import { STYLE_SETTINGS } from 'predictor-constants'
import RightSidebar from '../right-sidebar/RightSidebar'
import ThemeControl from './ThemeControl'

const Header: React.FC = () => {
  const { user, headerTitleContainerRef } = usePredictorContext()

  const appBarSideActions = (
    <Stack direction='row' alignItems='center' gap={1} mr={1}>
      <ThemeControl />
      <Divider orientation='vertical' flexItem />
      {user && <UserAvatar size={30} user={user} />}
    </Stack>
  )

  const appBar = (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      sx={{ mr: { xl: `${STYLE_SETTINGS.RIGHT_SIDEBAR_WIDTH}px` } }}
    >
      <Stack direction='row' flexGrow={1} sx={{ pl: { lg: `calc(${STYLE_SETTINGS.DRAWER_WIDTH}px + 2rem)` } }}>
        <Stack direction='row' ref={headerTitleContainerRef}>
          {/* Title */}
        </Stack>
        {/* Space for other stuff. Empty for now */}
      </Stack>
      {appBarSideActions}
    </Stack>
  )

  const mainMenuToggle = (
    <>
      <Hidden lgDown>
        <MainMenu />
      </Hidden>
      <Hidden lgUp>
        <IconButton size='large' edge='start' color='inherit' aria-label='menu' sx={{ mx: 1 }}>
          <MainMenu />
        </IconButton>
      </Hidden>
    </>
  )

  const rightSidebarToggle = (
    <>
      <Hidden xlDown>
        <RightSidebar />
      </Hidden>
      <Hidden xlUp>
        <Divider flexItem orientation='vertical' sx={{ my: 1 }} />
        <IconButton size='large' edge='end' color='inherit'>
          <RightSidebar />
        </IconButton>
      </Hidden>
    </>
  )

  return (
    <AppBar
      position='fixed'
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar variant='dense' disableGutters>
        {mainMenuToggle}
        {appBar}
        {rightSidebarToggle}
      </Toolbar>
    </AppBar>
  )
}

export default Header
