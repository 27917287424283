import { apiGet } from 'modules/api/predictor-api-client'
import { AxiosInstance } from 'axios'

const endpointBase = '/tournament'
const endpoint = (tournamentId?: string, suffix?: string) =>
  `${endpointBase}${tournamentId ? `/${tournamentId}` : ''}${suffix ? `/${suffix}` : ''}`

const getTournamentGroups = async (client: AxiosInstance, tournamentId: string) => await apiGet(client, endpoint(tournamentId, 'group'))

export { getTournamentGroups }
