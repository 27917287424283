import { Stack, Typography } from '@mui/material'
import { UserAvatar } from 'components'
import { PredictionInputLogEntry } from 'modules/api/types/admin'
import { DISPLAY_DATE_FORMAT_S } from 'predictor-constants'
import { format as formatDate } from 'date-fns'
import { PredictionInputLogEntryDetails } from './components'
import { ModalOptions } from 'modules/ui'

const renderEntryParticipant = (entry: PredictionInputLogEntry): React.ReactElement => {
  const { gameAlias, user } = entry.gameParticipant

  return (
    <Stack direction='row' gap={1} alignItems='center'>
      <UserAvatar user={{ name: user?.name ?? '', picture: user?.picture }} size='small' />
      <Typography variant='body2'>{gameAlias}</Typography>
    </Stack>
  )
}

const getEntryModalConfig = (entry: PredictionInputLogEntry): ModalOptions => ({
  maxSize: 'lg',
  title: `${entry.gameParticipant.gameAlias} - ${formatDate(new Date(entry.dateTime), DISPLAY_DATE_FORMAT_S)}`,
  renderContent: () => <PredictionInputLogEntryDetails entry={entry} />,
})

export { renderEntryParticipant, getEntryModalConfig }
