import { Components, createTheme } from '@mui/material/styles'
import { teal, deepOrange, grey } from '@mui/material/colors'
import { Theme, experimental_sx as sx } from '@mui/material'

const defaultComponents: Components<Omit<Theme, 'components'>> = {
  MuiToolbar: {
    styleOverrides: {
      root: ({ theme }) =>
        sx({
          backgroundColor: theme.palette.background.paper,
          color: 'primary.main',
        }),
    },
  },
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      '*::-webkit-scrollbar': {
        width: '0.4em',
        background: grey[100],
      },
      '*::-webkit-scrollbar-thumb': {
        background: grey[200],
      },
      '*::-webkit-scrollbar-thumb:hover': {
        background: grey[300],
      },
    },
  },
  MuiTooltip: {
    defaultProps: {
      // enterTouchDelay: 700,
      // leaveTouchDelay: 1500,
      disableTouchListener: true,
    },
  },
}

const PREDICTOR_THEME: Theme = createTheme({
  palette: {
    primary: {
      main: teal[300],
    },
    secondary: {
      main: deepOrange[500],
    },
    focus: grey[100],
    emphasis: grey[300],
  },
  typography: {
    fontSize: 13.5,
    h1: { fontSize: '3rem' },
    h2: { fontSize: '2.25rem' },
    h3: { fontSize: '2rem' },
    h4: { fontSize: '1.75rem' },
    h5: { fontSize: '1.5rem' },
    h6: { fontSize: '1.25rem' },
  },
  components: defaultComponents,
})

const PREDICTOR_THEME_DARK: Theme = createTheme({
  ...PREDICTOR_THEME,
  palette: {
    mode: 'dark',
    primary: {
      main: teal[300],
    },
    secondary: {
      main: deepOrange[500],
    },
    focus: grey[900],
    emphasis: grey[800],
  },
  components: {
    ...defaultComponents,
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          width: '0.4em',
          background: grey[900],
        },
        '*::-webkit-scrollbar-thumb': {
          background: grey[800],
        },
        '*::-webkit-scrollbar-thumb:hover': {
          background: grey[700],
        },
      },
    },
  },
})

export { PREDICTOR_THEME, PREDICTOR_THEME_DARK }
