import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { predictionTypes } from 'modules/api/types/admin'
import React from 'react'

interface IProps {
  value: string[]
  onChange: (value: string[]) => void
}

const PredictionTypeSelect: React.FC<IProps> = ({ value, onChange }) => {
  return (
    <FormControl sx={{ width: 200 }}>
      <InputLabel id='prediction-type-select-label'>Prediction Type</InputLabel>
      <Select
        labelId='prediction-type-select-label'
        id='prediction-type-select'
        value={value}
        label='Prediction Type'
        onChange={(e) => onChange(typeof e.target.value === 'string' ? [e.target.value] : e.target.value)}
        multiple={true}
        placeholder='Any type'
      >
        {predictionTypes.map((x) => (
          <MenuItem key={x} value={x}>
            {x}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default PredictionTypeSelect
