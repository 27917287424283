import { RefreshRounded } from '@mui/icons-material'
import { Alert, Button, Container, Divider, Hidden, IconButton, Stack, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { LoadingBox, PageTitle } from 'components'
import { usePredictorApi } from 'modules/api'
import { clearCache, getCache } from 'modules/api/requests/admin'
import { CacheEntry } from 'modules/api/types/admin'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { useState } from 'react'
import { DataGridState } from 'types/data-grid'

const Cache = () => {
  const getCacheApi = usePredictorApi<CacheEntry[]>({ apiFunc: (client) => getCache(client) })
  const cacheEntries = getCacheApi.data

  const clearCacheApi = usePredictorApi({ successMessage: 'Cache cleared', errorMessage: 'Error occured while clearing cache.' })

  const [dataGridState, setDataGridState] = useState<DataGridState>({ pageSize: 10, page: 0 })

  const onClearCacheClick = async () => {
    await clearCacheApi.requestWithParams((client) => clearCache(client))
    getCacheApi.request()
  }

  const getTagsDisplay = (cacheEntry: CacheEntry) => {
    return [cacheEntry.tag1, cacheEntry.tag2, cacheEntry.tag3, cacheEntry.tag4, cacheEntry.tag5].filter((x) => Boolean(x)).join(', ')
  }

  const columns: GridColDef[] = [
    { field: 'dateCreated', headerName: 'Created', width: 200 },
    { field: 'key', headerName: 'Key', width: 200 },
    { field: 'tags', headerName: 'Tags', width: 300, valueGetter: (params) => getTagsDisplay(params.row) },
    { field: 'id', headerName: 'ID', width: 300, sortable: false },
  ]

  return (
    <>
      <PageTitle
        title={
          <Stack direction='row' alignItems='center' gap={1}>
            <Typography>Cache</Typography>
            <Divider flexItem orientation='vertical' variant='middle' />
            <Button variant='contained' size='small' color='error' onClick={() => onClearCacheClick()}>
              Clear <Hidden smDown> Cache </Hidden>
            </Button>
            <Hidden smDown>
              <Divider flexItem orientation='vertical' variant='middle' />
              <IconButton onClick={() => getCacheApi.request()}>
                <RefreshRounded className={getCacheApi.isLoading ? 'spin' : ''} />
              </IconButton>
            </Hidden>
          </Stack>
        }
        icon={PREDICTOR_ROUTE_COLLECTION.cache.icon}
      />

      {getCacheApi.isLoading && !cacheEntries && <LoadingBox />}

      {getCacheApi.error && <Alert severity='error'>Error retrieving data. {getCacheApi.error}</Alert>}

      {cacheEntries && (
        <Container>
          <DataGrid
            rows={cacheEntries}
            columns={columns}
            autoHeight
            disableSelectionOnClick={true}
            rowsPerPageOptions={[10, 20, 50]}
            pageSize={dataGridState.pageSize}
            page={dataGridState.page}
            filterModel={dataGridState.filter}
            sortModel={dataGridState.sort}
            onPageSizeChange={(pageSize) => setDataGridState({ ...dataGridState, pageSize: pageSize })}
            onPageChange={(page) => setDataGridState({ ...dataGridState, page: page })}
            onFilterModelChange={(filter) => setDataGridState({ ...dataGridState, filter: filter })}
            onSortModelChange={(sort) => setDataGridState({ ...dataGridState, sort: sort })}
          />
        </Container>
      )}
    </>
  )
}

export default Cache
