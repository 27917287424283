import { apiDelete, apiGet, apiPut } from 'modules/api/predictor-api-client'
import { UpdateGroupPositionsRequest } from 'modules/api/types/admin'
import { AxiosInstance } from 'axios'
import { TournamentStage } from 'modules/api/types/stages'

const tournamentEndpoint = (tournamentId: string) => `/admin/tournament/${tournamentId}/team`
const teamEndpoint = (teamId?: string) => `/admin/team${teamId ? `/${teamId}` : ''}`
const stageEndpoint = (tournamentId: string, suffix?: string) => `admin/tournament/${tournamentId}/stage${suffix ? `/${suffix}` : ''}`

const getTournamentTeams = async (client: AxiosInstance, tournamentId: string) => await apiGet(client, tournamentEndpoint(tournamentId))
const getTeam = async (client: AxiosInstance, teamId: string) => await apiGet(client, teamEndpoint(teamId))

const updateGroupPositions = async (client: AxiosInstance, tournamentId: string, request: UpdateGroupPositionsRequest) =>
  await apiPut(client, stageEndpoint(tournamentId, 'group'), request)

const getStages = async (client: AxiosInstance, tournamentId: string) => await apiGet(client, stageEndpoint(tournamentId))
const getSpecificStage = async (client: AxiosInstance, tournamentId: string, stage: TournamentStage) =>
  await apiGet(client, stageEndpoint(tournamentId, stage))

const setStageTeam = async (client: AxiosInstance, tournamentId: string, stageCode: string, teamId: string) =>
  await apiPut(client, stageEndpoint(tournamentId, `${stageCode}/${teamId}`))

const deleteStageTeam = async (client: AxiosInstance, tournamentId: string, stageCode: string) =>
  await apiDelete(client, stageEndpoint(tournamentId, stageCode))

export { getTournamentTeams, getTeam, updateGroupPositions, getStages, getSpecificStage, setStageTeam, deleteStageTeam }
