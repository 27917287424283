import { Circle } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { PredictionInputLogEntry } from 'modules/api/types/admin'
import React, { ReactElement } from 'react'
import { format as formatDate } from 'date-fns'
import { DISPLAY_DATE_FORMAT_S } from 'predictor-constants'
import { renderEntryParticipant } from '../utils'

interface IProps {
  entry: PredictionInputLogEntry
}

const PredictionInputLogEntryDetails: React.FC<IProps> = ({ entry }) => {
  const row = (field: string, value: string | number | ReactElement) => ({ field, value })
  const rows: { field: string; value: string | number | ReactElement }[] = [
    row('Date', formatDate(new Date(entry.dateTime), DISPLAY_DATE_FORMAT_S)),
    row('Participant', renderEntryParticipant(entry)),
    row('Type', entry.predictionType),
    row('Operation', entry.inputOperation),
    row('Status', entry.inputStatus),
    row('Status Reason', entry.statusReason ?? 'N/A'),
    row('Input Data', entry.inputData),
    row('Participant Id', entry.gameParticipantId),
    row('ID', entry.id),
  ]
  return (
    <Box display='grid' gridTemplateColumns='auto 10px 1fr' gap={2} px={3} pb={2}>
      {rows.map((row) => (
        <React.Fragment key={row.field}>
          <Typography component='div'>
            <strong>{row.field}</strong>
          </Typography>
          <div style={{ fontSize: 8, marginTop: '7px' }}>
            <Circle fontSize='inherit' sx={{ color: 'emphasis' }} />
          </div>
          <Typography component='div'>{row.value}</Typography>
        </React.Fragment>
      ))}
    </Box>
  )
}

export default PredictionInputLogEntryDetails
