import { Typography } from '@mui/material'
import { Process } from 'modules/api/types/admin'
import { ModalOptions } from 'modules/ui'
import ProcessDetails from '../components/ProcessDetails'

const getProcessColor = (process: Process): string | undefined => {
  switch (process.status) {
    case 'Waiting':
    case 'Running':
      return 'primary'
    case 'Cancelled':
      return 'orange'
    case 'Error':
      return 'error'
    default:
      return 'undefined'
  }
}

const renderProcessStatus = (process: Process) => <Typography color={getProcessColor(process)}>{process.status}</Typography>

const getProcessModalConfig = (process: Process): ModalOptions => ({
  maxSize: 'lg',
  title: process.name,
  renderContent: () => <ProcessDetails process={process} />,
})

export { renderProcessStatus, getProcessModalConfig }
