import { apiGet } from 'modules/api/predictor-api-client'
import { AxiosInstance } from 'axios'

const endpoint = (processId?: string) => `/admin/process${processId ? `/${processId}` : ''}`
const chainEndpoint = (chainId: string) => `/admin/process/chain/${chainId}`

const getProcesses = async (
  client: AxiosInstance,
  pageSize: number,
  pageNumber: number,
  status?: string,
  name?: string,
  chain?: string,
) => {
  let queryString = `?pageSize=${pageSize}&pageNumber=${pageNumber}`

  if (status) {
    queryString += `&status=${status}`
  }
  if (name) {
    queryString += `&name=${name}`
  }
  if (chain) {
    queryString += `&chain=${chain}`
  }

  return await apiGet(client, `${endpoint()}${queryString}`)
}

const getProcessChain = async (client: AxiosInstance, chainId: string) => await apiGet(client, chainEndpoint(chainId))
const getRunningProcesses = async (client: AxiosInstance) => await apiGet(client, endpoint('running'))

export { getProcesses, getProcessChain, getRunningProcesses }
