import { RefreshRounded } from '@mui/icons-material'
import { Alert, Box, Container, Hidden, IconButton, Stack } from '@mui/material'
import { usePredictorApi } from 'modules/api'
import { getProcesses, getTournament, getTournamentFixtures } from 'modules/api/requests/admin'
import { Fixture, Process, Tournament } from 'modules/api/types/admin'
import { LoadingBox, PageNotFound, PageTitle } from 'components'
import { useItemMenuContext } from 'modules/ui'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React, { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { generateRouteWithId } from 'utils/route-utils'
import { FixtureListItem } from '../components'
import { usePredictorContext } from 'context/PredictorContext'
import { SearchResult } from 'types/search'

const FixtureList = () => {
  const { mainContainerRef } = usePredictorContext()
  const { tournamentId } = useParams()
  if (tournamentId === undefined) return <PageNotFound />

  const getTournamentApi = usePredictorApi<Tournament>({ apiFunc: (client) => getTournament(client, tournamentId) })
  const tournament = getTournamentApi.data

  const getFixturesApi = usePredictorApi<Fixture[]>({ apiFunc: (client) => getTournamentFixtures(client, tournamentId) })
  const fixtures = getFixturesApi.data

  const apiGetUpdateFixtureProcesses = usePredictorApi<SearchResult<Process>>({
    apiFunc: (client) => getProcesses(client, 1000, 1, undefined, 'UpdateFixture'),
  })
  const updateFixtureProcesses = apiGetUpdateFixtureProcesses.data

  const navigate = useNavigate()
  const { openMenu } = useItemMenuContext()

  const scrollToFixture = useRef<null | HTMLDivElement>(null)

  const fixtureIdToScrollTo = () => {
    if (!fixtures || fixtures.length === 0) return undefined

    const fixturesWithFullTime = fixtures.filter((x) => x.fixtureStatus === 'FullTime')

    if (!fixturesWithFullTime || fixturesWithFullTime.length === 0) return undefined

    return fixturesWithFullTime[fixturesWithFullTime.length - 1].id
  }

  useEffect(() => {
    setTimeout(() => {
      if (!scrollToFixture.current || !mainContainerRef?.current) return
      mainContainerRef.current.scrollTo(0, scrollToFixture.current.offsetTop - 110)
    }, 100)
  }, [fixtures])

  const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>, fixture: Fixture) => {
    openMenu(event.currentTarget, fixture, [
      {
        key: 'edit-status',
        icon: React.createElement(PREDICTOR_ROUTE_COLLECTION.editFixtureStatus.icon),
        title: 'Edit Status',
        onClick: (fixture) => navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.editFixtureStatus, (fixture as Fixture).id)),
      },
      {
        key: 'edit-general-details',
        icon: React.createElement(PREDICTOR_ROUTE_COLLECTION.editFixtureGeneralDetails.icon),
        title: 'Edit Date',
        onClick: () => navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.editFixtureGeneralDetails, fixture.id)),
      },
    ])
  }

  return (
    <>
      {tournament && (
        <PageTitle
          title={
            <>
              {tournament?.name}
              <Hidden smDown> - {PREDICTOR_ROUTE_COLLECTION.fixtures.title}</Hidden>{' '}
              <Hidden smDown>
                <IconButton onClick={() => getFixturesApi.request()}>
                  <RefreshRounded className={getFixturesApi.isLoading ? 'spin' : ''} />
                </IconButton>
              </Hidden>
            </>
          }
          icon={PREDICTOR_ROUTE_COLLECTION.fixtures.icon}
        />
      )}

      {(getTournamentApi.isLoading || (getFixturesApi.isLoading && !fixtures)) && <LoadingBox />}

      {(getTournamentApi.error || getFixturesApi.error) && (
        <Alert severity='error'>Error retrieving data. {getTournamentApi.error || getFixturesApi.error}</Alert>
      )}

      {getTournamentApi.data && fixtures && (
        <Container maxWidth='md'>
          <Stack gap={1}>
            {fixtures && (
              <>
                {fixtures.map((fixture) => (
                  <Box key={fixture.id} ref={fixtureIdToScrollTo() === fixture.id ? scrollToFixture : undefined}>
                    <FixtureListItem
                      fixture={fixture}
                      onOpenMenu={onOpenMenu}
                      latestUpdateFixtureProcess={updateFixtureProcesses?.pageResult.find(
                        (x) => x.processContext === `{"FixtureId":"${fixture.id}"}`,
                      )}
                    />
                  </Box>
                ))}
              </>
            )}
          </Stack>
        </Container>
      )}
    </>
  )
}

export default FixtureList
