import React, { PropsWithChildren, useContext, useState } from 'react'
import { ItemMenuItem } from 'types/item-menu'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <ItemMenuContext>.')
}

interface IItemMenuContextState {
  openMenu: <T>(anchorEl: HTMLElement, entity: T, menuItems: ItemMenuItem[]) => void
}

const ItemMenuContext = React.createContext<IItemMenuContextState>({ openMenu: stub })

const ItemMenuContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [entity, setEntity] = useState<unknown>()
  const [menuItems, setMenuItems] = useState<ItemMenuItem[]>([])
  const open = Boolean(anchorEl)

  const handleOpenMenu = <T,>(el: HTMLElement, data: T, items: ItemMenuItem[]) => {
    if (!el || data === undefined) return

    setAnchorEl(el)
    setEntity(data)
    setMenuItems(items)
  }

  const handleClick = (mi: ItemMenuItem) => {
    mi.onClick(entity)
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(undefined)
    setEntity(undefined)
    setMenuItems([])
  }

  return (
    <ItemMenuContext.Provider value={{ openMenu: handleOpenMenu }}>
      {children}

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems &&
          entity !== undefined &&
          menuItems.map((mi) => (
            <MenuItem key={mi.key} onClick={() => handleClick(mi)}>
              {mi.icon && <ListItemIcon>{mi.icon}</ListItemIcon>}
              <ListItemText>{mi.title}</ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </ItemMenuContext.Provider>
  )
}

const useItemMenuContext = () => useContext(ItemMenuContext)

export { ItemMenuContextProvider, useItemMenuContext }
