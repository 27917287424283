import { RefreshRounded } from '@mui/icons-material'
import { Alert, Container, Hidden, IconButton } from '@mui/material'
import { LoadingBox, PageNotFound, PageTitle } from 'components'
import { usePredictorApi } from 'modules/api'
import { getStages, getTournament } from 'modules/api/requests/admin'
import { AdminTournamentStageResponse, Tournament } from 'modules/api/types/admin'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React from 'react'
import { useParams } from 'react-router-dom'
import { KnockoutTabs } from '../components'

const Knockout = () => {
  const { tournamentId } = useParams()
  if (tournamentId === undefined) return <PageNotFound />

  const getTournamentApi = usePredictorApi<Tournament>({ apiFunc: (client) => getTournament(client, tournamentId) })
  const tournament = getTournamentApi.data

  const getStagesApi = usePredictorApi<AdminTournamentStageResponse[]>({ apiFunc: (client) => getStages(client, tournamentId) })
  const stages = getStagesApi.data

  return (
    <>
      {tournament && (
        <PageTitle
          title={
            <>
              {tournament?.name}
              <Hidden smDown> - {PREDICTOR_ROUTE_COLLECTION.knockout.title}</Hidden>{' '}
              <Hidden smDown>
                <IconButton onClick={() => getStagesApi.request()}>
                  <RefreshRounded className={getStagesApi.isLoading ? 'spin' : ''} />
                </IconButton>
              </Hidden>
            </>
          }
          icon={PREDICTOR_ROUTE_COLLECTION.knockout.icon}
        />
      )}

      {(getTournamentApi.isLoading || (getStagesApi.isLoading && !stages)) && <LoadingBox />}

      {(getTournamentApi.error || getStagesApi.error) && (
        <Alert severity='error'>Error retrieving data. {getTournamentApi.error || getStagesApi.error}</Alert>
      )}

      {getTournamentApi.data && stages && (
        <Container maxWidth='lg' disableGutters={true}>
          <KnockoutTabs tournamentId={tournamentId} stages={stages} onStagesUpdated={async () => await getStagesApi.request()} />
        </Container>
      )}
    </>
  )
}

export default Knockout
