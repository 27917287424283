import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { TeamName } from 'components'
import { Team } from 'modules/api/types/admin'
import React from 'react'
import { FormFieldProps } from 'types/form'

interface IProps extends FormFieldProps {
  teams: Team[]
}

const PlayerTeamField: React.FC<IProps> = ({ field, onChange, teams }) => {
  return (
    <FormControl error={!field.isValid}>
      <InputLabel id='team-label'>Team</InputLabel>
      <Select labelId='team-label' id='team' value={field.value} label='Team' onChange={(e) => onChange(e.target.value)}>
        <MenuItem value=''></MenuItem>
        {teams.map((x) => (
          <MenuItem key={x.id} value={x.code}>
            <TeamName team={x} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default PlayerTeamField
