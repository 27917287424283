import PredictorEntity from '../predictor-entity'
import { GameParticipant } from './game'

export const predictionTypes = ['Default', 'FixturePrediction', 'TeamPrediction', 'PredictedScorer'] as const
export type PredictionType = typeof predictionTypes[number]

export const predictionInputOperations = ['Default', 'Insert', 'Update', 'Delete', 'InsertOrUpdate'] as const
export type PredictionInputOperation = typeof predictionInputOperations[number]

export const predictionInputStatuses = ['Unconfirmed', 'Successful', 'Denied', 'Error'] as const
export type PredictionInputStatuse = typeof predictionInputStatuses[number]

export interface PredictionInputLogEntry extends PredictorEntity {
  gameParticipantId: string
  dateTime: string
  predictionType: PredictionType
  inputOperation: PredictionInputOperation
  inputData: string
  inputStatus: PredictionInputStatuse
  statusReason?: string
  gameParticipant: GameParticipant
}
