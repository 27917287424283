import { usePredictorApi } from 'modules/api'
import { Tournament, TournamentInsertRequest, TournamentTypes } from 'modules/api/types/admin'
import { Link, PageTitle } from 'components'
import { INPUT_DATE_FORMAT, PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { addMonths, format as formatDate, parse as parseDate } from 'date-fns'
import { useConfirmationModalContext } from 'modules/ui'
import { createTournament, getTournamentTypes } from 'modules/api/requests/admin'
import { Button, Container, Stack } from '@mui/material'
import { useForm } from 'modules/form'
import { FormFieldValue, validateDateString, validateRequired } from 'types/form'
import { TournamentNameField, TournamentTypeField, TournamentStartDateField, TournamentIsActiveField } from '../components'
import { useNavigate } from 'react-router-dom'
import { generateRouteWithId } from 'utils/route-utils'
import { ArrowBack } from '@mui/icons-material'
import { FormEvent } from 'react'

const NewTournament = () => {
  const navigate = useNavigate()

  const apiTournamentTypes = usePredictorApi<TournamentTypes>({ apiFunc: getTournamentTypes })
  const tournamentTypes = apiTournamentTypes.data

  const apiCreateTournament = usePredictorApi({
    errorMessage: 'Could not create tournament. {{error}}',
    successMessage: 'Tournament created.',
  })

  const { showConfirm } = useConfirmationModalContext()

  const form = useForm({
    tournamentName: { validations: [(value, options) => validateRequired(value, { ...options, fieldDisplayName: 'Tournament Name' })] },
    tournamentType: { validations: [validateRequired] },
    startDate: {
      defaultValue: formatDate(addMonths(new Date(), 2), INPUT_DATE_FORMAT),
      validations: [
        validateRequired,
        (value, options) => validateDateString(value, { ...options, format: INPUT_DATE_FORMAT, fieldDisplayName: 'Start Date' }),
      ],
    },
    isActive: { defaultValue: false },
  })

  const { tournamentName, tournamentType, startDate, isActive } = form.fields

  const onTournamentTypeChange = (newValue: FormFieldValue) => {
    if (!tournamentTypes) return
    form.set.tournamentType(newValue)
    form.set.tournamentName(tournamentTypes[newValue as string])
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (
      await showConfirm({
        title: 'Create Tournament',
        message: 'Are you sure you want to create this tournament?',
        confirmText: 'Create Tournament',
      })
    ) {
      const insertRequest: TournamentInsertRequest = {
        name: tournamentName.value as string,
        tournamentType: tournamentType.value as string,
        startDate: parseDate(startDate.value as string, INPUT_DATE_FORMAT, new Date()),
        isActive: isActive.value as boolean,
      }
      const resp = await apiCreateTournament.requestWithParams((theme) => createTournament(theme, insertRequest))

      if (resp?.status === 200) navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.editTournament, (resp.data as Tournament).id))
    }
  }

  const renderForm = () => (
    <Container maxWidth='xs' sx={{ mt: 3 }}>
      <form onSubmit={onSubmit}>
        <Stack gap={3}>
          <TournamentTypeField field={tournamentType} onChange={onTournamentTypeChange} tournamentTypes={tournamentTypes} />
          <TournamentNameField field={tournamentName} onChange={form.set.tournamentName} />
          <TournamentStartDateField field={startDate} onChange={form.set.startDate} />
          <TournamentIsActiveField field={isActive} onChange={form.set.isActive} />

          <Button variant='contained' disabled={!form.isValid} type='submit'>
            Create tournament
          </Button>
          <Link icon={<ArrowBack />} onClick={() => navigate(PREDICTOR_ROUTE_COLLECTION.tournaments.path)}>
            Back to tournaments
          </Link>
        </Stack>
      </form>
    </Container>
  )

  return (
    <>
      <PageTitle title={PREDICTOR_ROUTE_COLLECTION.newTournament.title} icon={PREDICTOR_ROUTE_COLLECTION.newTournament.icon} />
      {apiTournamentTypes.data && renderForm()}
    </>
  )
}

export default NewTournament
