import { MoreVert } from '@mui/icons-material'
import { Chip, Grid, Hidden, IconButton, Paper, Theme, useMediaQuery, Typography } from '@mui/material'
import { format } from 'date-fns'
import { Fixture, Process } from 'modules/api/types/admin'
import { getProcessModalConfig } from 'modules/processes'
import { useModalContext } from 'modules/ui'
import { DISPLAY_DATE_FORMAT_S } from 'predictor-constants'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getFixtureDescription, getFixtureStatusIcon, renderFixtureStatusText } from '../utils/fixture-utils'
import MatchWithGoals from './MatchWithGoals'

interface IProps {
  fixture: Fixture
  onOpenMenu: (event: React.MouseEvent<HTMLButtonElement>, fixture: Fixture) => void
  latestUpdateFixtureProcess?: Process
}

const FixtureListItem: React.FC<IProps> = ({ fixture, onOpenMenu, latestUpdateFixtureProcess }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { openModal } = useModalContext()

  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const menuIcon = (
    <IconButton onClick={(e) => onOpenMenu(e, fixture)}>
      <MoreVert />
    </IconButton>
  )

  const renderMatchDetails = () => {
    navigate(`/fixture/${fixture.code}`, {
      state: { backgroundLocation: location, props: fixture },
    })
  }

  const renderFixtureStatus = (): React.ReactElement | string => (
    <>
      <Hidden smDown>{getFixtureStatusIcon(fixture)} </Hidden>
      {renderFixtureStatusText(fixture)}
    </>
  )

  const onProcessClick = (process: Process) => {
    openModal(getProcessModalConfig(process))
  }

  return (
    <Paper sx={{ width: '100%', p: 1 }}>
      <Grid container columnSpacing={2} rowSpacing={0} justifyContent='space-between' alignItems='center'>
        {/* Fixture Description */}
        <Grid item xs={3}>
          <Chip size='small' label={getFixtureDescription(fixture, isSmDown)} />
        </Grid>
        {/* Fixture Status */}
        <Grid item xs={6} display='flex' alignItems='center' justifyContent='center' gap={1}>
          {renderFixtureStatus()}
        </Grid>
        {/* Menu Icon */}
        <Grid item xs={3} display='flex' justifyContent='end'>
          {menuIcon}
        </Grid>
        {/* Match with score and goals */}
        <Grid item xs={12}>
          <MatchWithGoals fixture={fixture} onScoreClick={renderMatchDetails} />
        </Grid>
        <Grid
          item
          xs={12}
          textAlign='center'
          pt={0.5}
          sx={{ cursor: latestUpdateFixtureProcess ? 'pointer' : 'initial' }}
          onClick={latestUpdateFixtureProcess ? () => onProcessClick(latestUpdateFixtureProcess) : undefined}
        >
          <Typography variant='caption'>
            Update Process:{' '}
            <Typography variant='caption' color='secondary'>
              {latestUpdateFixtureProcess?.utcEndTime
                ? format(new Date(latestUpdateFixtureProcess.utcEndTime), DISPLAY_DATE_FORMAT_S)
                : 'Not run'}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default FixtureListItem
