import { AddCircle, RemoveCircle, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Alert, Box, Collapse, Divider, IconButton, Stack } from '@mui/material'
import { usePredictorApi } from 'modules/api'
import { deleteGoal, getFixture } from 'modules/api/requests/admin'
import { Fixture, GoalTeamType } from 'modules/api/types/admin'
import { Link, LoadingBox, TeamName } from 'components'
import React, { ReactElement, useEffect, useState } from 'react'
import { isSuccessStatusCode } from 'utils/request-utils'
import AddGoalForm from './AddGoalForm'
import GoalDisplay from './GoalDisplay'

interface IProps {
  fixture: Fixture
}

const EditFixtureGoals: React.FC<IProps> = ({ fixture: fixtureProp }) => {
  const [fixture, setFixture] = useState(fixtureProp)
  const [addGoalFormCollapseOpen, setAddGoalFormCollapseOpen] = useState(false)

  const apiGetFixture = usePredictorApi({ errorMessage: 'Could not get updated fixture.' })
  const apiDeleteGoal = usePredictorApi({ successMessage: 'Goal removed.', errorMessage: 'Error removing goal.' })

  useEffect(() => {
    if (!apiGetFixture.data) return

    setFixture(apiGetFixture.data as Fixture)
  }, [apiGetFixture.data])

  const getUpdatedFixture = async () => {
    await apiGetFixture.requestWithParams((client) => getFixture(client, fixture.id))
  }

  const removeGoal = async (goalId: string) => {
    const response = await apiDeleteGoal.requestWithParams((client) => deleteGoal(client, goalId))
    if (isSuccessStatusCode(response)) await getUpdatedFixture()
  }

  const renderGoalRows = (team: GoalTeamType): ReactElement => {
    const goals = fixture.goals.filter((goal) => goal.team === team).sort((a, b) => a.time.localeCompare(b.time))

    return (
      <Stack>
        {goals.map((goal) => (
          <Stack key={goal.id} gap={1} direction='row' justifyContent='space-between' alignItems='center'>
            <GoalDisplay goal={goal} typographyProps={{ variant: 'body1' }} />
            <IconButton color='error' onClick={() => removeGoal(goal.id)}>
              <RemoveCircle />
            </IconButton>
          </Stack>
        ))}
      </Stack>
    )
  }

  if (apiGetFixture.error || apiDeleteGoal.error) return <Alert severity='error'>An error occurred. Please refresh and try again.</Alert>

  if (!fixture) return <LoadingBox />

  const addGoalColor = addGoalFormCollapseOpen ? 'inherit' : 'primary'

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        onClick={() => setAddGoalFormCollapseOpen(!addGoalFormCollapseOpen)}
        sx={{ cursor: 'pointer' }}
      >
        <Link
          color={addGoalColor}
          sx={{
            width: '100%',
            direction: 'row',
            justifyContent: 'space-between',
            '&:hover': {
              backgroundColor: 'focus',
            },
          }}
        >
          <Stack direction='row' alignItems='center' gap={1}>
            <AddCircle /> Add Goal
          </Stack>
          <Box mr={1}>{addGoalFormCollapseOpen ? <ExpandLess /> : <ExpandMore />}</Box>
        </Link>
      </Stack>
      <Collapse in={addGoalFormCollapseOpen} timeout='auto' unmountOnExit>
        <AddGoalForm sx={{ mt: 3 }} fixture={fixture} onAddGoal={getUpdatedFixture} />
      </Collapse>

      <Divider sx={{ mt: 3 }} />
      <Stack gap={1} mt={3}>
        {fixture?.homeTeam && <TeamName team={fixture?.homeTeam} />}
        {renderGoalRows('Home')}
        <Divider />
        {fixture?.awayTeam && <TeamName team={fixture?.awayTeam} />}
        {renderGoalRows('Away')}
      </Stack>
    </>
  )
}

export default EditFixtureGoals
