import React from 'react'
import { DISPLAY_DATE_FORMAT, PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { Divider, Hidden, IconButton, Paper, Stack, Typography } from '@mui/material'
import { Tournament } from 'modules/api/types/admin'
import { Circle, Edit, MoreVert } from '@mui/icons-material'
import { format as formatDate } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useItemMenuContext } from 'modules/ui'
import { generateRouteWithId } from 'utils/route-utils'
import { Link } from 'components'

interface IProps {
  tournament: Tournament
  onTitleClick: (tournament: Tournament) => void
}

const TournamentListItem: React.FC<IProps> = ({ tournament, onTitleClick }) => {
  const navigate = useNavigate()
  const { openMenu } = useItemMenuContext()

  const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>, tournament: Tournament) => {
    openMenu(event.currentTarget, tournament, [
      {
        key: 'edit',
        icon: <Edit />,
        title: (
          <>
            Edit{' '}
            <Typography component='span' color={(theme) => theme.palette.primary.main}>
              {tournament.name}
            </Typography>
          </>
        ),
        onClick: (tournament) => navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.editTournament, (tournament as Tournament).id)),
      },
      {
        key: 'fixtures',
        icon: React.createElement(PREDICTOR_ROUTE_COLLECTION.fixtures.icon),
        title: PREDICTOR_ROUTE_COLLECTION.fixtures.title,
        onClick: (tournament) => navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.fixtures, (tournament as Tournament).id)),
      },
      {
        key: 'groups',
        icon: React.createElement(PREDICTOR_ROUTE_COLLECTION.groups.icon),
        title: PREDICTOR_ROUTE_COLLECTION.groups.title,
        onClick: (tournament) => navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.groups, (tournament as Tournament).id)),
      },
    ])
  }

  return (
    <Paper sx={{ width: '100%', p: 2 }}>
      <Stack direction='row' alignItems='center'>
        <Stack flexGrow={1} gap={1}>
          <Stack direction='row' gap={1} alignItems='center'>
            <Link variant='h5' onClick={() => onTitleClick(tournament)}>
              {tournament.name}
            </Link>
            <Hidden mdDown>
              <Typography variant='caption' color={(theme) => theme.palette.grey[600]}>
                ({tournament.id})
              </Typography>
            </Hidden>
          </Stack>

          <Stack direction='row' gap={1}>
            <Typography variant='body2'>{formatDate(new Date(tournament.startDate), DISPLAY_DATE_FORMAT)}</Typography>
            <Divider flexItem orientation='vertical' />
            <Typography variant='body2' color='primary'>
              {tournament.games.length} games
            </Typography>
          </Stack>
        </Stack>

        <Stack direction='row' alignItems='center' gap={1}>
          <Hidden mdDown>
            <Typography variant='body2' color={tournament.isActive ? 'success' : 'error'}>
              {tournament.isActive ? 'Active' : 'Not active'}
            </Typography>
          </Hidden>
          <Circle color={tournament.isActive ? 'success' : 'error'} />
        </Stack>
        <IconButton onClick={(e) => onOpenMenu(e, tournament)}>
          <MoreVert />
        </IconButton>
      </Stack>
    </Paper>
  )
}

export default TournamentListItem
