import React, { PropsWithChildren, useContext, useLayoutEffect, useState } from 'react'
import { Modal } from '../components'
import { ModalOptions } from '../types'
import { useNavigationBlock } from 'hooks'

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <ModalContext>.')
}

interface IModalContextState {
  openModal: (options?: ModalOptions) => void
  closeModal: () => void
}

const ModalContext = React.createContext<IModalContextState>({ openModal: stub, closeModal: stub })

const ModalContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { popTransaction } = useNavigationBlock(modalOpen)

  const [modalOptions, setModalOptions] = useState<ModalOptions>({
    title: '',
    renderContent: () => <></>,
  })

  const handleOpenModal = (options?: ModalOptions) => {
    setModalOpen(true)
    if (options) setModalOptions(options)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  useLayoutEffect(() => {
    if (!popTransaction) return

    setModalOpen(false)
  }, [popTransaction])

  return (
    <ModalContext.Provider value={{ openModal: handleOpenModal, closeModal: handleCloseModal }}>
      {children}
      <Modal open={modalOpen} onClose={handleCloseModal} {...modalOptions} />
    </ModalContext.Provider>
  )
}

const useModalContext = () => useContext(ModalContext)

export { ModalContextProvider, useModalContext }
