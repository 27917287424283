import { Alert, Button, Container, Stack } from '@mui/material'
import { usePredictorApi } from 'modules/api'
import { deleteTournament, getTournament, patchTournament } from 'modules/api/requests/admin'
import { useConfirmationModalContext } from 'modules/ui'
import { useForm } from 'modules/form'
import React, { FormEvent, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { validateDateString, validateRequired } from 'types/form'
import { format as formatDate, parse as parseDate } from 'date-fns'
import { INPUT_DATE_FORMAT, PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { Tournament, TournamentPatchRequest } from 'modules/api/types/admin'
import { TournamentNameField, TournamentStartDateField, TournamentIsActiveField } from '../components'
import { PageTitle, Link } from 'components'
import { ArrowBack } from '@mui/icons-material'

const EditTournament = () => {
  const navigate = useNavigate()

  const { tournamentId } = useParams()

  if (!tournamentId) return <Alert severity='error'>Tournament ID not specified</Alert>

  const apiGetTournament = usePredictorApi<Tournament>({
    apiFunc: (client) => getTournament(client, tournamentId),
    errorMessage: 'Could not get tournament.',
  })
  const tournament = apiGetTournament.data

  const apiPatchTournament = usePredictorApi({
    errorMessage: 'Could not update tournament. {{error}}',
    successMessage: 'Tournament updated.',
  })

  const apiDeleteTournament = usePredictorApi({
    errorMessage: 'Could not delete tournament. {{error}}',
    successMessage: 'Tournament deleted.',
  })

  const { showConfirm } = useConfirmationModalContext()

  const form = useForm({
    tournamentName: { validations: [(value, options) => validateRequired(value, { ...options, fieldDisplayName: 'Tournament Name' })] },
    startDate: {
      validations: [
        validateRequired,
        (value, options) => validateDateString(value, { ...options, format: INPUT_DATE_FORMAT, fieldDisplayName: 'Start Date' }),
      ],
    },
    isActive: { defaultValue: false },
    phase: { defaultValue: '' },
  })

  const { tournamentName, startDate, isActive, phase } = form.fields

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (
      await showConfirm({
        title: 'Update Tournament',
        message: 'Are you sure you want to update this tournament? This has an effect on games and participants!',
        confirmText: 'Update',
      })
    ) {
      const patchRequest: TournamentPatchRequest = {
        name: tournamentName.value as string,
        startDate: parseDate(startDate.value as string, INPUT_DATE_FORMAT, new Date()),
        isActive: isActive.value as boolean,
        phase: phase.value as string,
      }
      await apiPatchTournament.requestWithParams((client) => patchTournament(client, tournamentId, patchRequest))
    }
  }

  const onDeleteClick = async () => {
    if (
      await showConfirm({
        title: 'Delete Tournament',
        message: 'Are you sure you want to delete this tournament?',
        confirmText: 'Delete',
        isDangerConfirm: true,
      })
    ) {
      const resp = await apiDeleteTournament.requestWithParams((client) => deleteTournament(client, tournamentId))
      if (resp?.status == 200) navigate(PREDICTOR_ROUTE_COLLECTION.tournaments.path)
    }
  }

  useEffect(() => {
    if (tournament) {
      form.set.tournamentName(tournament.name)
      const date = typeof tournament.startDate === 'string' ? new Date(tournament.startDate) : tournament.startDate
      form.set.startDate(formatDate(date, INPUT_DATE_FORMAT))
      form.set.isActive(tournament.isActive)
      form.set.phase(tournament.phase)
    }
  }, [tournament])

  const renderForm = () => (
    <Container maxWidth='xs' sx={{ mt: 3 }}>
      <form onSubmit={onSubmit}>
        <Stack gap={3}>
          <TournamentNameField field={tournamentName} onChange={form.set.tournamentName} />
          <TournamentStartDateField field={startDate} onChange={form.set.startDate} />
          <TournamentIsActiveField field={isActive} onChange={form.set.isActive} />
          <Stack direction='row' gap={1}>
            <Button variant='contained' disabled={!form.isValid} type='submit' fullWidth={true}>
              Save
            </Button>
            <Button variant='contained' color='error' onClick={onDeleteClick} fullWidth={true}>
              Delete Tournament
            </Button>
          </Stack>
          <Link icon={<ArrowBack />} onClick={() => navigate(PREDICTOR_ROUTE_COLLECTION.tournaments.path)}>
            Back to tournaments
          </Link>
        </Stack>
      </form>
    </Container>
  )

  return (
    <>
      <PageTitle title={tournament?.name ?? 'Edit Tournament'} icon={PREDICTOR_ROUTE_COLLECTION.editTournament.icon} />
      {tournament && renderForm()}
    </>
  )
}

export default EditTournament
