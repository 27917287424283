import { TournamentStage } from 'modules/api/types'
import _ from 'lodash'
import { AdminTournamentStageResponse } from 'modules/api/types/admin'

const separator = '-'

interface Stage {
  key: string
  prefix: string
  displayName: string
  displayNameShort: string
  isGroupStage: boolean
  subStage?: string // e.g. A from GRP-A
  identifier?: string // e.g. 1 from GRP-A-1 or 1 from R16-1
}

interface Stages {
  [name: string]: Stage
}

interface StageView extends Stage {
  key: string
  prefix: string
  displayName: string
  displayNameShort: string
  isGroupStage: boolean
  subStage?: string | undefined
  identifier?: string | undefined
  segments: AdminTournamentStageResponse[]
}

const stage = (key: string, displayName: string, displayNameShort: string, prefix: string, isGroupStage = false): Stage => ({
  key,
  displayName,
  displayNameShort,
  prefix: prefix.toUpperCase() + separator,
  isGroupStage,
})

const stages: Stages = {
  group: stage('group', 'Group', 'GRP', 'GRP', true),
  r32: stage('r32', 'Round of 32', 'R32', 'R32'),
  r16: stage('r16', 'Round of 16', 'R16', 'R16'),
  qf: stage('qf', 'Quarter Final', 'QF', 'QF'),
  sf: stage('sf', 'Semi Final', 'SF', 'SF'),
  fn: stage('fn', 'Final', 'FNL', 'FN'),
}

const getStageFromCode = (code: string): Stage | undefined => {
  const prefix = code.substring(0, code.indexOf(separator) + 1)
  const suffix = code.substring(code.indexOf(separator) + 1)
  const key = Object.keys(stages).find((key) => stages[key].prefix === prefix)
  if (!key) return undefined

  const stage = stages[key]
  if (stage.isGroupStage)
    return {
      ...stage,
      subStage: suffix.substring(0, suffix.indexOf(separator)),
      identifier: suffix.substring(suffix.indexOf(separator) + 1),
    }

  return { ...stage, identifier: suffix }
}

const getStageDisplayName = (code: string, shortVersion = false): string => {
  const stage = getStageFromCode(code)
  if (!stage) return ''
  if (stage.isGroupStage) return `${shortVersion ? stage.displayNameShort : stage.displayName} ${stage.subStage}`

  return shortVersion ? stage.displayNameShort : stage.displayName
}

const getStage = (stage: TournamentStage): Stage => stages[stage]

const getGroupedStages = (stagesInput: AdminTournamentStageResponse[]): StageView[] => {
  const grouped = _.groupBy(stagesInput, (entry) => getStageFromCode(entry.stage)?.key)
  const stageKeys = Object.keys(stages)
  const sorted = Object.keys(grouped)
    .sort((a, b) => stageKeys.indexOf(a) - stageKeys.indexOf(b))
    .map((k) => ({ ...stages[k], segments: grouped[k] }))

  return sorted
}

export { getStageFromCode, getStageDisplayName, getStage, getGroupedStages }
