import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import axios, { AxiosInstance } from 'axios'
const { apiBaseUrl } = window.config

const getApiClient = async (
  getAccessTokenFn: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
): Promise<AxiosInstance> => {
  const token = await getAccessTokenFn({
    audience: 'https://api.predictor.unclebenportal.net',
    scope: 'globaladmin',
  })

  return axios.create({
    baseURL: apiBaseUrl,
    headers: { Authorization: `Bearer ${token}` },
  })
}

const apiSend = async (client: AxiosInstance, method: string, endpoint: string, data?: unknown) => {
  return await client.request({
    method,
    url: endpoint,
    data,
  })
}

const apiGet = async (client: AxiosInstance, endpoint: string) => await apiSend(client, 'get', endpoint)
const apiPost = async (client: AxiosInstance, endpoint: string, data?: unknown) => await apiSend(client, 'post', endpoint, data)
const apiPatch = async (client: AxiosInstance, endpoint: string, data?: unknown) => await apiSend(client, 'patch', endpoint, data)
const apiPut = async (client: AxiosInstance, endpoint: string, data?: unknown) => await apiSend(client, 'put', endpoint, data)
const apiDelete = async (client: AxiosInstance, endpoint: string) => await apiSend(client, 'delete', endpoint)

export { getApiClient, apiGet, apiPost, apiPatch, apiPut, apiDelete }
