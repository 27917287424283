import { Button } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { TeamName } from 'components'
import { usePredictorApi } from 'modules/api'
import { deletePlayer as deletePlayerRequest } from 'modules/api/requests/admin'
import { Player } from 'modules/api/types/admin'
import React, { useState } from 'react'
import { DataGridState } from 'types/data-grid'
import { isSuccessStatusCode } from 'utils/request-utils'

interface IProps {
  players: Player[]
  onPlayerUpdated?: () => void
}

const PlayerList: React.FC<IProps> = ({ players, onPlayerUpdated }) => {
  const apiDeletePlayer = usePredictorApi({ successMessage: 'Player deleted.' })

  const [dataGridState, setDataGridState] = useState<DataGridState>({ pageSize: 20, page: 0, sort: [{ field: 'team', sort: 'asc' }] })

  const deletePlayer = async (playerId: string) => {
    const resp = await apiDeletePlayer.requestWithParams((client) => deletePlayerRequest(client, playerId))
    if (isSuccessStatusCode(resp)) {
      onPlayerUpdated && onPlayerUpdated()
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'team',
      width: 150,
      headerName: 'Team',
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.team.name,
      renderCell: (params) => <TeamName team={params.row.team} />,
    },
    { field: 'playerName', headerName: 'Player Name', width: 200 },
    { field: 'id', headerName: 'ID', width: 280, sortable: false },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Button variant='outlined' color='error' onClick={() => deletePlayer(params.row.id)}>
          Delete
        </Button>
      ),
    },
  ]

  return (
    <>
      {players && (
        <DataGrid
          rows={players}
          columns={columns}
          autoHeight
          disableSelectionOnClick={true}
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={dataGridState.pageSize}
          page={dataGridState.page}
          filterModel={dataGridState.filter}
          sortModel={dataGridState.sort}
          onPageSizeChange={(pageSize) => setDataGridState({ ...dataGridState, pageSize: pageSize })}
          onPageChange={(page) => setDataGridState({ ...dataGridState, page: page })}
          onFilterModelChange={(filter) => setDataGridState({ ...dataGridState, filter: filter })}
          onSortModelChange={(sort) => setDataGridState({ ...dataGridState, sort: sort })}
        />
      )}
    </>
  )
}

export default PlayerList
