import { useEffect, useState } from 'react'
import { Form, FormField, FormFieldDefinitions, FormFieldValue, FormState, SetFunctions } from 'types/form'

const getFormStateFromDefinitions = (fieldDefinitions: FormFieldDefinitions): FormState => {
  const fields: { [name: string]: FormField } = {}
  Object.keys(fieldDefinitions).forEach((fieldName) => {
    const fieldDef = fieldDefinitions[fieldName]
    fields[fieldName] = {
      value: fieldDef.defaultValue ?? '',
      validationErrors: [],
      isValid: true,
    }
  })

  return {
    fields,
    isValid: true,
  }
}

const useForm = (fieldDefinitions: FormFieldDefinitions): Form => {
  const [formState, setFormState] = useState<FormState>(getFormStateFromDefinitions(fieldDefinitions))

  const isFormValid = (inputFormState: FormState) => {
    let valid = true
    Object.keys(fieldDefinitions).some((fieldName) => {
      if (getFieldValidationErrors(fieldName, inputFormState.fields[fieldName], inputFormState).length > 0) {
        valid = false
        return true // break
      }
    })
    return valid
  }

  const setValue = (fieldName: string, value: FormFieldValue) => {
    if (!Object.keys(formState.fields).includes(fieldName))
      throw new Error(`Field with name ${fieldName} not found in form. Make sure it is included in the definitions.`)

    const newFormState = { ...formState }
    const field = newFormState.fields[fieldName]
    field.value = value
    field.validationErrors = getFieldValidationErrors(fieldName, field, newFormState)
    field.isValid = field.validationErrors.length === 0
    newFormState.isValid = isFormValid(newFormState)

    setFormState(newFormState)
  }

  const getFieldValidationErrors = (fieldName: string, field: FormField, formState: FormState): string[] => {
    const fieldDef = fieldDefinitions[fieldName]
    const errors: string[] = []

    fieldDef.validations?.forEach((validationFn) => {
      errors.push(...validationFn(field.value, { formState }))
    })

    return errors
  }

  const setFns: SetFunctions = {}
  Object.keys(fieldDefinitions).forEach((fieldName) => {
    setFns[fieldName] = (value: FormFieldValue) => setValue(fieldName, value)
  })

  const resetFn = () => {
    const newFormState = { ...formState }
    Object.keys(newFormState.fields).forEach((fieldName) => {
      const defaultFieldValue = fieldDefinitions[fieldName].defaultValue
      newFormState.fields[fieldName] = {
        ...newFormState.fields[fieldName],
        value: defaultFieldValue ?? '',
        isValid: true,
        validationErrors: [],
      }
    })
    newFormState.isValid = isFormValid(newFormState)
    setFormState(newFormState)
  }

  useEffect(() => {
    // Run validation on first run but without setting fields as invalid, only form.
    if (!isFormValid(formState)) setFormState({ ...formState, isValid: false })
  }, [])

  return {
    ...formState,
    setValue,
    set: setFns,
    reset: resetFn,
  }
}

export default useForm
