import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React, { useState } from 'react'
import { DataGridState } from 'types/data-grid'
import { ScorerValidationErrorListDataRow } from '../types'

interface IProps {
  rows: ScorerValidationErrorListDataRow[]
  columns: GridColDef[]
}

const ScorerValidationErrorListDataList: React.FC<IProps> = ({ rows, columns }) => {
  const [dataGridState, setDataGridState] = useState<DataGridState>({ pageSize: 20, page: 0, sort: [{ field: 'team', sort: 'asc' }] })

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      autoHeight
      disableSelectionOnClick={true}
      rowsPerPageOptions={[10, 20, 50]}
      pageSize={dataGridState.pageSize}
      page={dataGridState.page}
      filterModel={dataGridState.filter}
      sortModel={dataGridState.sort}
      onPageSizeChange={(pageSize) => setDataGridState({ ...dataGridState, pageSize: pageSize })}
      onPageChange={(page) => setDataGridState({ ...dataGridState, page: page })}
      onFilterModelChange={(filter) => setDataGridState({ ...dataGridState, filter: filter })}
      onSortModelChange={(sort) => setDataGridState({ ...dataGridState, sort: sort })}
    />
  )
}

export default ScorerValidationErrorListDataList
