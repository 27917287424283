import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import React from 'react'
import { processNames } from '../types'

interface IProps {
  value: string[]
  onChange: (value: string[]) => void
}

const ProcessNameSelect: React.FC<IProps> = ({ value, onChange }) => {
  return (
    <FormControl sx={{ width: 200 }}>
      <InputLabel id='process-name-select-label'>Name</InputLabel>
      <Select
        labelId='process-name-select-label'
        id='process-name-select'
        value={value}
        label='Name'
        onChange={(e) => onChange(typeof e.target.value === 'string' ? [e.target.value] : e.target.value)}
        multiple={true}
        placeholder='Any name'
      >
        {processNames.map((x) => (
          <MenuItem key={x} value={x}>
            {x}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ProcessNameSelect
