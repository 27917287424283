import { RefreshRounded } from '@mui/icons-material'
import { Container, Hidden, IconButton } from '@mui/material'
import { PageNotFound, PageTitle } from 'components'
import { usePredictorApi } from 'modules/api'
import { getTournament, getTournamentPlayers, validateScorers } from 'modules/api/requests/admin'
import { Player, ScorerValidationError, Tournament } from 'modules/api/types/admin'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ScorerValidationErrorList } from '../components'

const ValidateScorers = () => {
  const { tournamentId } = useParams()
  if (tournamentId === undefined) return <PageNotFound />

  const getTournamentApi = usePredictorApi<Tournament>({ apiFunc: (client) => getTournament(client, tournamentId) })
  const tournament = getTournamentApi.data

  const apiGetPlayers = usePredictorApi<Player[]>()
  const players = apiGetPlayers.data?.map((x) => ({ ...x, team: tournament?.teams?.find((t) => t.code === x.teamCode) })) ?? []

  const apiValidateScorers = usePredictorApi<ScorerValidationError[]>()
  const validationErrors =
    apiValidateScorers.data?.map((x) => ({ ...x, team: tournament?.teams?.find((t) => t.code === x.teamCode) })) ?? []

  useEffect(() => {
    if (!tournament) return
    apiGetPlayers.requestWithParams((client) => getTournamentPlayers(client, tournament.id))
    apiValidateScorers.requestWithParams((client) => validateScorers(client, tournament.id))
  }, [tournament])

  const onScorersUpdated = () => {
    if (!tournament) return
    apiValidateScorers.requestWithParams((client) => validateScorers(client, tournament.id))
  }

  return (
    <Container>
      {tournament && (
        <PageTitle
          title={
            <>
              {tournament?.name}
              <Hidden smDown> - {PREDICTOR_ROUTE_COLLECTION.validateScorers.title}</Hidden>
              <Hidden smDown>
                <IconButton onClick={() => onScorersUpdated()}>
                  <RefreshRounded className={apiValidateScorers.isLoading ? 'spin' : ''} />
                </IconButton>
              </Hidden>
            </>
          }
          icon={PREDICTOR_ROUTE_COLLECTION.validateScorers.icon}
        />
      )}
      {tournament && players && validationErrors && (
        <ScorerValidationErrorList
          tournament={tournament}
          players={players}
          validationErrors={validationErrors}
          onScorerUpdated={onScorersUpdated}
        />
      )}
    </Container>
  )
}

export default ValidateScorers
