import { Avatar, SxProps, Tooltip, useTheme } from '@mui/material'
import React from 'react'

interface IProps {
  user: { name: string; picture?: string }
  size?: 'default' | 'small' | number
  sx?: SxProps
}

const UserAvatar: React.FC<IProps> = ({ user, size = 'default', sx }): JSX.Element => {
  if (!user) return <> </>

  const theme = useTheme()

  let sxFinal: SxProps = {
    ...sx,
    border: `2px solid ${theme.palette.background.paper}`,
  }

  if (size)
    sxFinal = {
      ...sxFinal,
      width: size === 'small' ? 20 : size,
      height: size === 'small' ? 20 : size,
    }

  return (
    <Tooltip title={user.name}>
      <Avatar src={user.picture} alt={user.name} sx={sxFinal} />
    </Tooltip>
  )
}

export default UserAvatar
