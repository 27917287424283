import { Alert, Button, Container, Typography } from '@mui/material'
import { LoadingBox, PageTitle, UserAvatar } from 'components'
import { usePredictorApi } from 'modules/api'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React, { useState } from 'react'
import { AdminPredictorUser } from 'modules/api/types/admin'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Image } from '@mui/icons-material'
import { usePredictorContext } from 'context/PredictorContext'
import { disableUser, getAllUsers } from 'modules/api/requests/admin'
import { DataGridState } from 'types/data-grid'
import { useConfirmationModalContext } from 'modules/ui'

const UserList = () => {
  const apiGetAllUsers = usePredictorApi({ apiFunc: getAllUsers })
  const apiDisableUser = usePredictorApi()

  const { user } = usePredictorContext()
  const [dataGridState, setDataGridState] = useState<DataGridState>({ pageSize: 10, page: 0 })

  const { showConfirm } = useConfirmationModalContext()

  const users = apiGetAllUsers.data as AdminPredictorUser[]

  const toggleDisabled = async (user: AdminPredictorUser) => {
    const toDisable = !user.isDisabled
    const confirmResult = await showConfirm({
      title: `${toDisable ? 'Disable' : 'Enable'} user`,
      renderMessage: () => (
        <>
          <Typography>Are you sure you want to {toDisable ? 'disable' : 'enable'} this user?</Typography>
          <Typography variant='h6' color='primary' mt={1}>
            {user.name}
          </Typography>
          <Typography variant='body2' color='primary'>
            {user.email}
          </Typography>
        </>
      ),
      isDangerConfirm: toDisable,
      confirmText: toDisable ? 'Disable' : 'Enable',
    })

    if (confirmResult) {
      const resp = await apiDisableUser.requestWithParams((client) => disableUser(client, user.id, toDisable), {
        successMessage: `User ${toDisable ? 'disabled' : 'enabled'}`,
      })
      if (resp) {
        apiGetAllUsers.request()
      }
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'picture',
      width: 30,
      align: 'center',
      headerName: 'Picture',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => <Image sx={{ fontSize: '1rem' }} />,
      renderCell: (params) => <UserAvatar size={30} user={params.row} />,
    },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'isDisabled',
      headerName: 'Disabled',
      renderCell: (params) =>
        params.value ? (
          <Typography variant='body2' color='error'>
            Disabled
          </Typography>
        ) : (
          <Typography variant='body2'>Enabled</Typography>
        ),
    },
    { field: 'id', headerName: 'ID', width: 300, sortable: false },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Button
          disabled={user?.email === params.row.email}
          variant='outlined'
          color={params.row.isDisabled ? 'primary' : 'error'}
          onClick={() => toggleDisabled(params.row)}
        >
          {params.row.isDisabled ? 'Enable' : 'Disable'}
        </Button>
      ),
    },
  ]

  if (apiGetAllUsers.isLoading) {
    return <LoadingBox />
  }

  if (apiGetAllUsers.error) return <Alert severity='error'>{apiGetAllUsers.error}</Alert>

  return (
    <Container>
      <PageTitle title={PREDICTOR_ROUTE_COLLECTION.users.title} icon={PREDICTOR_ROUTE_COLLECTION.users.icon} />
      {apiGetAllUsers.isLoading && <LoadingBox />}
      {apiGetAllUsers.error && <Alert severity='error'>{apiGetAllUsers.error}</Alert>}
      {!apiGetAllUsers.isLoading && users && (
        <DataGrid
          rows={users}
          columns={columns}
          autoHeight
          disableSelectionOnClick={true}
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={dataGridState.pageSize}
          page={dataGridState.page}
          filterModel={dataGridState.filter}
          sortModel={dataGridState.sort}
          onPageSizeChange={(pageSize) => setDataGridState({ ...dataGridState, pageSize: pageSize })}
          onPageChange={(page) => setDataGridState({ ...dataGridState, page: page })}
          onFilterModelChange={(filter) => setDataGridState({ ...dataGridState, filter: filter })}
          onSortModelChange={(sort) => setDataGridState({ ...dataGridState, sort: sort })}
        />
      )}
    </Container>
  )
}

export default UserList
