import { Autocomplete, TextField } from '@mui/material'
import { Player, Team } from 'modules/api/types/admin'
import React from 'react'
import { FormFieldProps } from 'types/form'

interface IProps extends FormFieldProps {
  team?: Team
  players?: Player[]
}

const GoalScorerNameField: React.FC<IProps> = ({ field, onChange, players, team }) => {
  const options = team && players ? players.filter((x) => x.teamCode === team.code).map((x) => x.playerName) : []

  return (
    <Autocomplete
      disablePortal
      id='scorer-name'
      disabled={!team || !players}
      options={options}
      onChange={(e, value) => onChange(value ? (value as string) : '')}
      value={field.value ? (field.value as string) : ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Scorer name'
          required
          error={!field.isValid}
          helperText={field.isValid ? '' : field.validationErrors[0]}
        />
      )}
    />
  )
}

export default GoalScorerNameField
