export const processStatuses = ['Waiting', 'Running', 'Finished', 'Error', 'Cancelled'] as const
export const processNames = [
  'CalculateGroupStandings',
  'CreateEmptyFixturePredictions',
  'CreateGame',
  'SetFixturePrediction',
  'SetGroupPositions',
  'SetPredictedScorers',
  'SetTeamPrediction',
  'SetTeamToStage',
  'UpdateFixture',
  'UpdateFixturePredictionPoints',
  'UpdateGroupTeamPoints',
  'UpdateStandings',
  'UpdateTeamPredictionPoints',
  'UserJoinedGame',
  'UserLoggedIn',
] as const

export type ProcessStatus = typeof processStatuses[number]
export type ProcessName = typeof processNames[number]
