import { Card, CardContent, CardHeader, Divider, Portal, Typography } from '@mui/material'
import { usePredictorApi } from 'modules/api'
import { getRunningProcesses } from 'modules/api/requests/admin'
import { Process } from 'modules/api/types/admin'
import { useModalContext } from 'modules/ui'
import { usePredictorContext } from 'context/PredictorContext'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React, { useEffect } from 'react'
import { getProcessModalConfig, renderProcessStatus } from '../utils/process-utils'

const RightSidebarProcesses = () => {
  const apiGetRunningProcesses = usePredictorApi({ apiFunc: getRunningProcesses, errorMessage: '' })
  const processes = apiGetRunningProcesses.data as Process[]
  const { rightSidebarContentRef } = usePredictorContext()
  const { openModal } = useModalContext()

  useEffect(() => {
    const interval = setInterval(() => apiGetRunningProcesses.request(), 2000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const onProcessClick = (process: Process) => {
    openModal(getProcessModalConfig(process))
  }

  if (!processes || processes.length === 0 || !rightSidebarContentRef?.current) return <></>

  return (
    <Portal container={rightSidebarContentRef.current}>
      {processes.map((process) => (
        <Card
          key={process.id}
          onClick={() => onProcessClick(process)}
          sx={{
            cursor: 'pointer',
            '&:hover': { backgroundColor: 'focus' },
          }}
        >
          <CardHeader
            avatar={React.createElement(PREDICTOR_ROUTE_COLLECTION.processes.icon)}
            title={renderProcessStatus(process)}
            subheader={process.name}
            sx={{ pb: 0 }}
          ></CardHeader>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Divider />
            <Typography component='div' variant='caption' sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              Context: {process.processContext}
            </Typography>
            <Typography component='div' variant='caption'>
              Running time: {process.runTimeInMs} ms
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Portal>
  )
}

export default RightSidebarProcesses
