import { apiGet } from 'modules/api/predictor-api-client'
import { AxiosInstance } from 'axios'

const endpoint = (suffix?: string) => `/admin/predictionInputLog${suffix ? `/${suffix}` : ''}`

const getPredictionInputLogEntries = async (
  client: AxiosInstance,
  pageSize: number,
  pageNumber: number,
  participant?: string,
  predictionType?: string,
) => {
  let queryString = `?pageSize=${pageSize}&pageNumber=${pageNumber}`

  if (participant) {
    queryString += `&participant=${participant}`
  }
  if (predictionType) {
    queryString += `&predictionType=${predictionType}`
  }

  return await apiGet(client, `${endpoint()}${queryString}`)
}

export { getPredictionInputLogEntries }
