import { Avatar } from '@mui/material'

export const APP_DETAILS = {
  TITLE: 'Predictor Admin',
  ICON: (
    <Avatar
      sx={{ width: '40px', height: '40px' }}
      variant='square'
      alt='Predictor Admin Portal'
      src={`${process.env.PUBLIC_URL}/logo192.png`}
    />
  ),
  MAINLOGO: <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt='Predictor Admin Portal' width='192' height='192' />,
  WELCOMEMESSAGE: 'Administration only',
}

export const INPUT_DATE_FORMAT = 'dd/MM/yyyy HH:mm'
export const DISPLAY_DATE_FORMAT = 'dd MMM yyyy HH:mm'
export const DISPLAY_DATE_FORMAT_S = 'dd MMM yyyy HH:mm:ss.SSS'
