import { Fab, Stack, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { STYLE_SETTINGS } from 'predictor-constants'
import { usePredictorContext } from 'context/PredictorContext'
import { AddCircle } from '@mui/icons-material'

interface IProps {
  color?: 'secondary' | 'inherit' | 'default' | 'primary' | 'success' | 'error' | 'info' | 'warning' | undefined
  size?: 'medium' | 'small' | 'large' | undefined
  icon?: JSX.Element
  title?: string
  tooltip?: string
  onClick?: () => void
}

const PageButton: React.FC<IProps> = ({
  color = 'secondary',
  icon = <AddCircle />,
  size = 'medium',
  tooltip = 'Create',
  title = 'Create',
  onClick,
}) => {
  const { mainContainerRef, mainContentRef } = usePredictorContext()
  const isLargeVersion = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))

  const prevScrollY = useRef(0)
  const [goingUp, setGoingUp] = useState(false)

  const onScroll = (e: Event) => {
    const el = e.target as HTMLElement
    const currentScrollY = el.scrollTop

    if (prevScrollY.current < currentScrollY && goingUp) {
      setGoingUp(false)
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true)
    }

    prevScrollY.current = currentScrollY
  }

  useEffect(() => {
    if (!mainContainerRef || !mainContainerRef.current) return
    mainContainerRef.current.addEventListener('scroll', onScroll)

    return () => {
      if (!mainContainerRef || !mainContainerRef.current) return
      mainContainerRef.current.removeEventListener('scroll', onScroll)
    }
  }, [goingUp])

  const getRightPos = (): string => {
    let rightPos = isLargeVersion ? `calc(20px + ${STYLE_SETTINGS.RIGHT_SIDEBAR_WIDTH}px)` : '20px'
    const contentContainerRect = mainContentRef?.current?.getBoundingClientRect()
    if (contentContainerRect) rightPos = `${window.outerWidth - contentContainerRect.left - contentContainerRect.width + 10}px`
    return rightPos
  }

  const getBottomPos = (): string => {
    return `${STYLE_SETTINGS.FOOTER_HEIGHT - 20}px`
  }

  return (
    <Tooltip placement='left' title={tooltip}>
      <Fab
        color={color}
        size={size}
        variant={goingUp ? 'extended' : 'circular'}
        sx={{
          position: 'fixed',
          bottom: getBottomPos(),
          right: getRightPos(),
          zIndex: (theme) => theme.zIndex.drawer - 1,
          opacity: 0.9,
          '&:hover': { opacity: 1 },
        }}
        onClick={onClick}
      >
        <Stack direction='row' alignItems='center' gap={1}>
          {goingUp && <Typography>{title}</Typography>}
          {icon}
        </Stack>
      </Fab>
    </Tooltip>
  )
}
export default PageButton
