import { Box, FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import { FormFieldProps } from 'types/form'

const TournamentIsActiveField: React.FC<FormFieldProps> = ({ field, onChange }) => {
  return (
    <Box>
      <FormControlLabel
        labelPlacement='start'
        sx={{ ml: 0 }}
        control={<Switch checked={field.value as boolean} onChange={(e) => onChange(e.target.checked)} />}
        label='Active'
      />
    </Box>
  )
}

export default TournamentIsActiveField
