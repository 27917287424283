import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { usePredictorContext } from 'context/PredictorContext'

const MenuAuth: React.FC = (): JSX.Element => {
  const { logout } = useAuth0()
  const { user } = usePredictorContext()

  if (user) {
    return (
      <ListItemButton onClick={() => logout({ returnTo: window.location.origin })}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary='Logout' secondary={`Logged in as ${user?.name}`} />
      </ListItemButton>
    )
  }

  return <> </>
}

export default MenuAuth
