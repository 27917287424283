import { TextField } from '@mui/material'
import React from 'react'
import { FormFieldProps } from 'types/form'

const OutcomeDescriptionField: React.FC<FormFieldProps> = ({ field, onChange }) => {
  return (
    <TextField
      id='outcome-description'
      label='Outcome Description'
      variant='standard'
      error={!field.isValid}
      helperText='This is shown under the score if it has a value. E.g. 4-3 aet. Keep this as short as possible.'
      value={field.value}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export default OutcomeDescriptionField
