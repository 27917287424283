import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { Typography } from '@mui/material'

const LandingPage = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(PREDICTOR_ROUTE_COLLECTION.tournaments.path)
  }, [])

  return <Typography variant='body1'> You are being redirected. </Typography>
}

export default LandingPage
