import React, { PropsWithChildren, useContext, useRef, useState } from 'react'
import { ConfirmDialog } from '../components'
import { ConfirmOptions } from '../types'

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <ConfirmModalContext>.')
}

interface IConfirmModalContextState {
  showConfirm: (options?: ConfirmOptions) => Promise<boolean>
}

const ConfirmModalContext = React.createContext<IConfirmModalContextState>({ showConfirm: stub })

const ConfirmModalContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmOptions, setConfirmOptions] = useState<ConfirmOptions>({
    title: 'Confirm',
    message: 'Are you sure you want to do this action?',
  })
  const confirmResolver = useRef<(value: boolean) => void>()

  const handleShow = (options?: ConfirmOptions) => {
    setConfirmOpen(true)
    if (options) setConfirmOptions(options)

    return new Promise(function (resolve: (value: boolean) => void) {
      confirmResolver.current = resolve
    })
  }

  const handleOk = () => {
    confirmResolver.current && confirmResolver.current(true)
    setConfirmOpen(false)
  }

  const handleCancel = () => {
    confirmResolver.current && confirmResolver.current(false)
    setConfirmOpen(false)
  }

  return (
    <ConfirmModalContext.Provider value={{ showConfirm: handleShow }}>
      {children}
      <ConfirmDialog
        open={confirmOpen}
        title={confirmOptions.title}
        renderTitle={confirmOptions.renderTitle}
        message={confirmOptions.message}
        renderMessage={confirmOptions.renderMessage}
        confirmText={confirmOptions.confirmText}
        isDangerConfirm={confirmOptions.isDangerConfirm}
        onConfirm={handleOk}
        onCancel={handleCancel}
      />
    </ConfirmModalContext.Provider>
  )
}

const useConfirmationModalContext = () => useContext(ConfirmModalContext)

export { ConfirmModalContextProvider, useConfirmationModalContext }
