import { Close } from '@mui/icons-material'
import { Box, Typography, Modal as MuiModal, Theme, Stack, Toolbar, AppBar, Backdrop, styled } from '@mui/material'
import { STYLE_SETTINGS } from 'predictor-constants'
import React, { ReactElement } from 'react'
import { ModalAction, ModalOptions } from '../types'
import ModalFooter from './ModalFooter'

interface IModalProps extends ModalOptions {
  open: boolean
  onClose: () => void
}

const Modal: React.FC<IModalProps> = ({
  open,
  title,
  renderTitle,
  renderContent,
  onClose,
  actions,
  maxSize,
  hideFooter = false,
  footerComponent,
  contentComponent,
}) => {
  const size = maxSize ?? 'sm'

  const defaultModalStyle = (theme: Theme) => ({
    width: { xs: '100vw', sm: '80vw', md: theme.breakpoints.values[size] },
    height: { xs: '100vh', sm: 'auto' },
    maxHeight: { sm: `calc(100vh - ${STYLE_SETTINGS.HEADER_HEIGHT * 2}px)` },
    position: 'absolute',
    top: { xs: 0, sm: '50%' },
    left: { xs: 0, sm: '50%' },
    transform: { xs: 'inherit', sm: 'translate(-50%, -50%)' },
    backgroundColor: theme.palette.background.paper,
  })

  const backdrop = styled(Backdrop, {
    name: 'MuiModal',
    slot: 'Backdrop',
    overridesResolver: (props, styles) => {
      return styles.backdrop
    },
  })({ backgroundColor: 'rgba(0,0,0,0.9)' })

  const closeAction: ModalAction = {
    key: 'close',
    title: 'Close',
    buttonProps: { variant: 'outlined', endIcon: <Close /> },
  }

  const renderFooter = (): ReactElement | undefined => {
    if (hideFooter) return

    return footerComponent ?? <ModalFooter actions={[...(actions ?? []), closeAction]} onCloseModal={onClose} />
  }

  return (
    <MuiModal open={open} onClose={onClose} components={{ Backdrop: backdrop }}>
      <Stack sx={defaultModalStyle}>
        <AppBar position='relative'>
          <Toolbar variant='dense'>
            {renderTitle ? (
              renderTitle()
            ) : (
              <Typography variant='h6' component='h6'>
                {title}
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        {contentComponent && contentComponent}
        {!contentComponent && (
          <Box sx={{ flexGrow: 1, p: 1, pt: 3, overflow: 'auto', minHeight: '100px' }}>{renderContent && renderContent()}</Box>
        )}
        {renderFooter()}
      </Stack>
    </MuiModal>
  )
}

export default Modal
