import { Hidden, Portal, Stack, SvgIconTypeMap, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { usePredictorContext } from 'context/PredictorContext'
import React from 'react'

interface IProps {
  title: string | React.ReactElement
  icon: OverridableComponent<SvgIconTypeMap>
}

const PageTitle: React.FC<IProps> = ({ title, icon }) => {
  const { headerTitleContainerRef } = usePredictorContext()

  return (
    <Portal container={headerTitleContainerRef?.current}>
      <Stack direction='row' alignItems='center' gap={1}>
        <Hidden smDown> {React.createElement(icon)} </Hidden>
        <Typography component='div' variant='body1'>
          {title}
        </Typography>
      </Stack>
    </Portal>
  )
}

export default PageTitle
