import { TextField } from '@mui/material'
import { FormFieldProps } from 'types/form'
import React from 'react'

const TournamentNameField: React.FC<FormFieldProps> = ({ field, onChange }) => {
  return (
    <TextField
      id='tournament-name'
      label='Tournament Name'
      variant='standard'
      error={!field.isValid}
      helperText={field.isValid ? 'The name of the tournament' : field.validationErrors[0]}
      required
      value={field.value}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export default TournamentNameField
