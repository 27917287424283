import { ArrowBack, Image, RefreshRounded } from '@mui/icons-material'
import { Alert, Container, Hidden, IconButton, Tooltip } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Link, LoadingBox, PageNotFound, PageTitle, UserAvatar } from 'components'
import { usePredictorApi } from 'modules/api'
import { getGameStandings } from 'modules/api/requests'
import { GameStandings as GameStandingsType, GameStandingsEntry, GameStandingsPoint } from 'modules/api/types'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DataGridState } from 'types/data-grid'
import { generateRouteWithId } from 'utils/route-utils'

interface GameStandingsRow {
  position: number
  id: string
  alias: string
  picture: string
  points: GameStandingsPoint[]
  totalPoints: number
}

const GameStandings: React.FC = () => {
  const { gameId, tournamentId } = useParams()
  const navigate = useNavigate()
  if (tournamentId === undefined || gameId === undefined) return <PageNotFound />

  const getGameStandingsApi = usePredictorApi<GameStandingsType>({
    apiFunc: (client) => getGameStandings(client, gameId),
  })
  const gameStandings = getGameStandingsApi.data
  const entries: GameStandingsRow[] | undefined = gameStandings?.entries.map((x) => ({
    ...x.participant,
    points: x.points,
    totalPoints: x.totalPoints,
    position: x.position,
  }))

  const [dataGridState, setDataGridState] = useState<DataGridState>({ pageSize: 100, page: 0 })

  let columns: GridColDef[] = [
    {
      field: 'position',
      width: 10,
      align: 'left',
      headerName: '#',
      headerAlign: 'left',
      disableColumnMenu: true,
    },
    {
      field: 'picture',
      width: 30,
      align: 'center',
      headerName: 'Picture',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => <Image sx={{ fontSize: '1rem' }} />,
      renderCell: (params) => <UserAvatar size={30} user={{ name: params.row.alias, picture: params.row.picture }} />,
    },
    { field: 'alias', headerName: 'Alias', width: 150 },
  ]

  const cols: GridColDef[] =
    gameStandings?.pointDefinitions.map((x) => ({
      field: x.pointCode,
      width: 40,
      align: 'right',
      disableColumnMenu: true,
      headerAlign: 'right',
      renderHeader: () => (
        <Tooltip title={`${x.pointName} - ${x.pointDescription}`}>
          <div>{x.pointCode}</div>
        </Tooltip>
      ),
      valueGetter: (params) => (params.row as GameStandingsEntry).points.find((p) => p.pointCode === x.pointCode)?.totalPoints,
    })) ?? []

  const pointsCol: GridColDef = {
    field: 'points',
    width: 60,
    align: 'right',
    disableColumnMenu: true,
    headerAlign: 'right',
    renderHeader: () => (
      <Tooltip title='Total Points'>
        <div>PTS</div>
      </Tooltip>
    ),
    renderCell: (params) => (
      <div>
        <strong>{(params.row as GameStandingsEntry).totalPoints}</strong>
      </div>
    ),
  }

  const participantIdCol: GridColDef = { field: 'id', headerName: 'Participant ID', width: 280 }

  columns = [...columns, pointsCol, ...cols, participantIdCol]

  return (
    <>
      <PageTitle
        title={
          <>
            {PREDICTOR_ROUTE_COLLECTION.gameStandings.title}
            <Hidden smDown>
              <IconButton onClick={() => getGameStandingsApi.request()}>
                <RefreshRounded className={getGameStandingsApi.isLoading ? 'spin' : ''} />
              </IconButton>
            </Hidden>
          </>
        }
        icon={PREDICTOR_ROUTE_COLLECTION.gameStandings.icon}
      />

      {getGameStandingsApi.isLoading && !gameStandings && <LoadingBox />}

      {getGameStandingsApi.error && <Alert severity='error'>Error retrieving data. {getGameStandingsApi.error}</Alert>}

      {entries && (
        <Container>
          <DataGrid
            rows={entries}
            columns={columns}
            autoHeight
            disableSelectionOnClick={true}
            rowsPerPageOptions={[100]}
            pageSize={dataGridState.pageSize}
            page={dataGridState.page}
            filterModel={dataGridState.filter}
            sortModel={dataGridState.sort}
            onPageSizeChange={(pageSize) => setDataGridState({ ...dataGridState, pageSize: pageSize })}
            onPageChange={(page) => setDataGridState({ ...dataGridState, page: page })}
            onFilterModelChange={(filter) => setDataGridState({ ...dataGridState, filter: filter })}
            onSortModelChange={(sort) => setDataGridState({ ...dataGridState, sort: sort })}
          />

          <Link
            sx={{ mt: 3 }}
            icon={<ArrowBack />}
            onClick={() => navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.games, tournamentId))}
          >
            Back to games
          </Link>
        </Container>
      )}
    </>
  )
}
export default GameStandings
