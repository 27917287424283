import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { ConfirmOptions } from '../types'

interface IConfirmDialogProps extends ConfirmOptions {
  open: boolean
  onConfirm?: () => void
  onCancel?: () => void
}

const ConfirmDialog: React.FC<IConfirmDialogProps> = ({
  open,
  title,
  renderTitle,
  message,
  renderMessage,
  confirmText,
  isDangerConfirm,
  onConfirm,
  onCancel,
}) => (
  <Dialog maxWidth='sm' fullWidth open={open}>
    <DialogTitle>{renderTitle ? renderTitle() : title}</DialogTitle>
    <DialogContent>{renderMessage ? renderMessage() : message}</DialogContent>
    <DialogActions>
      <Button variant='outlined' autoFocus onClick={onCancel}>
        Cancel
      </Button>
      <Button variant='contained' onClick={onConfirm} color={isDangerConfirm ? 'error' : 'primary'}>
        {confirmText || 'Ok'}
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmDialog
