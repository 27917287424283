import { Button, ButtonTypeMap, Tooltip } from '@mui/material'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'
import { usePredictorApi } from 'modules/api'
import { updateScoreFromGoals } from 'modules/api/requests/admin'
import React from 'react'
import { isSuccessStatusCode } from 'utils/request-utils'

interface IProps {
  fixtureId: string
  onScoreUpdated?: () => void
  buttonProps?: DefaultComponentProps<ButtonTypeMap>
}

const UpdateFixtureScoreFromGoalsButton: React.FC<IProps> = ({ fixtureId, onScoreUpdated, buttonProps }) => {
  const apiUpdateScore = usePredictorApi({ successMessage: 'Score updated', errorMessage: 'Error updating scores.' })

  const updateScore = async () => {
    const response = await apiUpdateScore.requestWithParams((client) => updateScoreFromGoals(client, fixtureId))
    if (isSuccessStatusCode(response) && onScoreUpdated) onScoreUpdated()
  }

  return (
    <Tooltip title='This will update the score of this fixture according to the goals assigned to it in normal time.'>
      <Button {...buttonProps} onClick={updateScore}>
        Update score from goals
      </Button>
    </Tooltip>
  )
}

export default UpdateFixtureScoreFromGoalsButton
