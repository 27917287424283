import { Box, Button, Tooltip } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { TeamName } from 'components'
import { usePredictorApi } from 'modules/api'
import { updateScorerName as updateScorerNameRequest } from 'modules/api/requests/admin'
import { Player, ScorerNameUpdateRequest, ScorerValidationError, Tournament } from 'modules/api/types/admin'
import React, { useEffect, useState } from 'react'
import { isSuccessStatusCode } from 'utils/request-utils'
import { ScorerValidationErrorListDataRow } from '../types'
import ScorerValidationErrorListDataList from './ScorerValidationErrorListDataList'
import ScorerValidationNewNameField from './ScorerValidationNewNameField'

interface IProps {
  tournament: Tournament
  players: Player[]
  validationErrors: ScorerValidationError[]
  onScorerUpdated?: () => void
}

const ValidationErrorList: React.FC<IProps> = ({ tournament, players, validationErrors, onScorerUpdated }) => {
  const apiUpdateScorerName = usePredictorApi({ successMessage: 'Name updated in all predictions.' })

  const [scorerNameMap, setScorerNameMap] = useState<ScorerNameUpdateRequest[]>([])

  const [rows, setRows] = useState<ScorerValidationErrorListDataRow[]>([])

  const getPlayerMap = (teamCode: string, playerName: string): ScorerNameUpdateRequest | undefined =>
    scorerNameMap.find((x) => x.teamCode === teamCode && x.playerName === playerName)

  const updateScorerName = async (teamCode: string, playerName: string) => {
    const playerMap = getPlayerMap(teamCode, playerName)

    if (!playerMap?.newPlayerName) return

    const resp = await apiUpdateScorerName.requestWithParams((client) =>
      updateScorerNameRequest(client, tournament.id, { teamCode, playerName, newPlayerName: playerMap.newPlayerName }),
    )
    if (isSuccessStatusCode(resp)) {
      onScorerUpdated && onScorerUpdated()
    }
  }

  const updateScorerNameMap = (teamCode: string, scorerName: string, newName: string) => {
    const newScorer: ScorerNameUpdateRequest = { teamCode, playerName: scorerName, newPlayerName: newName }
    setScorerNameMap([...scorerNameMap.filter((x) => x.teamCode !== teamCode && x.newPlayerName !== scorerName), newScorer])
  }

  useEffect(() => {
    if (!validationErrors) return
    setRows(
      validationErrors.map((x) => ({
        ...x,
        id: `${x.teamCode}-${x.scorerName}`,
        newPlayerName: getPlayerMap(x.teamCode, x.scorerName)?.newPlayerName ?? '',
      })),
    )
  }, [validationErrors, scorerNameMap])

  const columns: GridColDef[] = [
    {
      field: 'team',
      width: 150,
      headerName: 'Team',
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.team.name,
      renderCell: (params) => <TeamName team={params.row.team} />,
    },
    { field: 'scorerName', headerName: 'Scorer Name', width: 200 },
    { field: 'count', headerName: 'Count', width: 100, headerAlign: 'right', align: 'right' },
    {
      field: 'newPlayerName',
      headerName: 'New Name',
      width: 200,
      sortable: false,
      valueGetter: (params) => getPlayerMap(params.row.teamCode, params.row.scorerName)?.newPlayerName,
      renderCell: (params) => (
        <Box m={1}>
          <ScorerValidationNewNameField
            playerNames={players
              .filter((x) => x.teamCode === params.row.teamCode)
              .map((x) => x.playerName)
              .sort()}
            newPlayerName={params.row.newPlayerName}
            onChange={(newPlayerName) => updateScorerNameMap(params.row.teamCode, params.row.scorerName, newPlayerName)}
          />
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Tooltip title='Update all predictions with this name to the mapped value.'>
          <Button variant='contained' fullWidth onClick={() => updateScorerName(params.row.teamCode, params.row.scorerName)}>
            Update
          </Button>
        </Tooltip>
      ),
    },
  ]

  return <>{rows && <ScorerValidationErrorListDataList rows={rows} columns={columns} />}</>
}

export default ValidationErrorList
