import { Link, LinkProps, LinkTypeMap, SxProps, Theme } from '@mui/material'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'
import React from 'react'

interface IProps extends DefaultComponentProps<LinkTypeMap> {
  icon?: React.ReactElement
  align?: 'left' | 'center' | 'right'
}

const PredictorLink: React.FC<IProps> = (props) => {
  const getJustifyContent = () => {
    if (props.align === undefined) return 'start'

    switch (props.align) {
      case 'left':
        return 'start'
      case 'center':
        return 'center'
      case 'right':
        return 'end'
    }
  }

  const defaultSx: SxProps<Theme> = {
    display: 'flex',
    cursor: 'pointer',
    '&:hover': { color: (theme) => (theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.primary.light) },
    width: props.align ? '100%' : 'inherit',
    alignItems: 'center',
    justifyContent: getJustifyContent(),
  }
  const finalProps: LinkProps = { underline: 'none', ...props, sx: { ...defaultSx, ...props.sx } }

  return (
    <Link {...finalProps}>
      {props.icon && props.icon}
      {props.children}
    </Link>
  )
}

export default PredictorLink
