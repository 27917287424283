import { Alert, Button, Container, Hidden, Stack } from '@mui/material'
import { usePredictorApi } from 'modules/api'
import { getFixture, patchFixture } from 'modules/api/requests/admin'
import { useForm } from 'modules/form'
import { FormEvent, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { validateDateString, validateRequired } from 'types/form'
import { INPUT_DATE_FORMAT, PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { Fixture, FixturePatchRequest } from 'modules/api/types/admin'
import { PageTitle, Link } from 'components'
import { ArrowBack, Save } from '@mui/icons-material'
import { getFixtureDescription } from '../utils/fixture-utils'
import { generateRouteWithId } from 'utils/route-utils'
import { format as formatDate, parse as parseDate } from 'date-fns'
import { FixtureDateField } from '../components/fields'
import { useConfirmationModalContext } from 'modules/ui'

const EditFixtureGeneralDetails = () => {
  const navigate = useNavigate()

  const { fixtureId } = useParams()

  if (!fixtureId) return <Alert severity='error'>Fixture ID not specified</Alert>

  const apiGetFixture = usePredictorApi<Fixture>({
    apiFunc: (client) => getFixture(client, fixtureId),
    errorMessage: 'Could not get fixture.',
  })
  const fixture = apiGetFixture.data

  const apiPatchFixture = usePredictorApi({
    errorMessage: 'Could not update fixture. {{error}}',
    successMessage: 'Fixture updated.',
  })

  const form = useForm({
    fixtureDate: {
      validations: [
        validateRequired,
        (value, options) => validateDateString(value, { ...options, format: INPUT_DATE_FORMAT, fieldDisplayName: 'Fixture Date' }),
      ],
    },
  })

  const { fixtureDate } = form.fields

  const { showConfirm } = useConfirmationModalContext()

  useEffect(() => {
    if (fixture) {
      const date = typeof fixture.dateTime === 'string' ? new Date(fixture.dateTime) : fixture.dateTime
      form.set.fixtureDate(formatDate(date, INPUT_DATE_FORMAT))
    }
  }, [fixture])

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const confirmed = await showConfirm({
      title: 'Update fixture',
      renderMessage: () => (
        <>
          Updating this fixture might have an effect on the tournament and on prediction time limits. <br />
          <br /> Are you sure you want to continue?
        </>
      ),
      confirmText: 'Update fixture',
    })

    if (!confirmed) return

    const patchRequest: FixturePatchRequest = {
      dateTime: parseDate(fixtureDate.value as string, INPUT_DATE_FORMAT, new Date()),
    }
    await apiPatchFixture.requestWithParams((client) => patchFixture(client, fixtureId, patchRequest))
  }

  const renderForm = () => {
    if (!fixture) return <></>

    return (
      <form onSubmit={onSubmit}>
        <Stack gap={3}>
          <FixtureDateField field={fixtureDate} onChange={form.set.fixtureDate} />
          <Button variant='contained' disabled={!form.isValid} type='submit' fullWidth={true} endIcon={<Save />}>
            Save
          </Button>
          <Link
            icon={<ArrowBack />}
            onClick={() => navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.fixtures, fixture?.tournamentId))}
          >
            Back to fixtures
          </Link>
        </Stack>
      </form>
    )
  }

  return (
    <>
      <PageTitle
        title={
          <>
            <Hidden smDown>Edit Fixture Details - </Hidden>
            {fixture && <strong>{getFixtureDescription(fixture, false, true)}</strong>}
          </>
        }
        icon={PREDICTOR_ROUTE_COLLECTION.editFixtureGeneralDetails.icon}
      />
      <Container maxWidth='xs'>{fixture && renderForm()}</Container>
    </>
  )
}

export default EditFixtureGeneralDetails
