import { Typography, Box, Hidden, Stack, useMediaQuery, Theme } from '@mui/material'
import React from 'react'

const Footer: React.FC = () => {
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const copyright: React.ReactElement = (
    <Typography variant='body2' color='text.secondary'>
      © {new Date().getFullYear()} prophetofsport.com
    </Typography>
  )

  const version: React.ReactElement = (
    <Typography variant='body2' color='emphasis'>
      v 1.0.1
    </Typography>
  )

  return (
    <Box
      component='footer'
      sx={{
        py: 2,
        px: 2,
        mt: 'auto',
        borderTop: (theme) => `solid 1px ${theme.palette.focus}`,
        backgroundColor: 'focus',
      }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Hidden smDown>
          <Box flexBasis={100}></Box>
        </Hidden>
        <Box flexGrow={1} textAlign={isSmDown ? 'left' : 'center'}>
          {copyright}
        </Box>
        <Box flexBasis={100} textAlign='right'>
          {version}
        </Box>
      </Stack>
    </Box>
  )
}

export default Footer
