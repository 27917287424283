import { MenuOpen } from '@mui/icons-material'
import { AppBar, Drawer, Hidden, Stack, Toolbar, Typography } from '@mui/material'
import { usePredictorContext } from 'context/PredictorContext'
import { STYLE_SETTINGS } from 'predictor-constants'
import React from 'react'

const RightSidebar: React.FC = () => {
  const [rightSidebarOpen, setRightSidebarOpen] = React.useState(false)
  const { rightSidebarContentRef } = usePredictorContext()

  const toggleRightSidebar = (open: boolean) => {
    setRightSidebarOpen(open)
  }

  const drawerContent = (
    <>
      <AppBar position='sticky'>
        <Toolbar variant='dense'>
          <Typography variant='h6' component='div'>
            Focus
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack sx={{ p: 2, mb: '48px' }} gap={1} ref={rightSidebarContentRef}>
        {/* {Array.from({ length: 20 }, (x, i) => (
          <Paper elevation={1} sx={{ p: 1 }}>
            <Typography variant='body2'>This is some text {i}</Typography>
          </Paper>
        ))} */}
      </Stack>
    </>
  )

  return (
    <>
      <Hidden xlUp>
        {!rightSidebarOpen && <MenuOpen onClick={() => toggleRightSidebar(true)} sx={{ mr: 1 }} />}
        {rightSidebarOpen && (
          <MenuOpen onClick={() => toggleRightSidebar(false)} color='secondary' sx={{ transform: 'scaleX(-1)', mr: 1 }} />
        )}
        <Drawer
          anchor='right'
          sx={{ '>.MuiDrawer-paper': { width: STYLE_SETTINGS.RIGHT_SIDEBAR_WIDTH } }}
          ModalProps={{
            keepMounted: true,
          }}
          open={rightSidebarOpen}
          onClose={() => setRightSidebarOpen(false)}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden xlDown>
        <Drawer anchor='right' variant='permanent' sx={{ '>.MuiDrawer-paper': { width: STYLE_SETTINGS.RIGHT_SIDEBAR_WIDTH } }}>
          {drawerContent}
        </Drawer>
      </Hidden>
    </>
  )
}

export default RightSidebar
