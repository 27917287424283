import { History, Transition } from 'history'
import { useCallback, useContext, useEffect, useState } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

type ExtendNavigator = Navigator & Pick<History, 'block'>

function useBlocker(blocker: (tx: Transition) => void, when = true) {
  const { navigator } = useContext(NavigationContext)
  const [popTransaction, setPopTransaction] = useState(false)

  useEffect(() => {
    if (!when) {
      setPopTransaction(false)
      return
    }

    const unblock = (navigator as unknown as ExtendNavigator).block((tx) => {
      setPopTransaction(tx.action === 'POP')
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [navigator, blocker, when])

  return { popTransaction }
}

export function useNavigationBlock(when = true) {
  const blocker = useCallback(() => void 0, [])

  return useBlocker(blocker, when)
}

export function useNavigationPrompt(message: string, when = true) {
  const blocker = useCallback(
    (tx: Transition) => {
      if (window.confirm(message)) tx.retry()
    },
    [message],
  )

  return useBlocker(blocker, when)
}
