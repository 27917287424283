import { Box, Grid, Tab, Tabs, Theme, Typography, useMediaQuery } from '@mui/material'
import { AdminTournamentStageResponse, StageTeam } from 'modules/api/types/admin'
import React, { useMemo, useState } from 'react'
import { getGroupedStages } from 'utils/stage-utils'
import KnockoutSegment from './KnockoutSegment'
import { useConfirmationModalContext } from 'modules/ui'
import { LoadingBackdrop, TeamName } from 'components'
import { usePredictorApi } from 'modules/api'
import { deleteStageTeam, setStageTeam } from 'modules/api/requests/admin'
import { isSuccessStatusCode } from 'utils/request-utils'

interface IProps {
  tournamentId: string
  stages: AdminTournamentStageResponse[]
  onStagesUpdated: () => void
}

const KnockoutTabs: React.FC<IProps> = ({ tournamentId, stages, onStagesUpdated }) => {
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const groupedStages = getGroupedStages(stages).filter((x) => !x.isGroupStage)
  const [selectedTab, setSelectedTab] = useState(groupedStages[0].key)
  const currentStageView = useMemo(() => groupedStages.find((x) => x.key === selectedTab), [selectedTab, groupedStages])
  const [knockoutSelectionDisabled, setKnockoutSelectionDisabled] = useState(false)

  const apiSetStageTeam = usePredictorApi({ successMessage: 'Stage winner set successfully.' })
  const apiDeleteStageTeam = usePredictorApi({ successMessage: 'Stage winner deleted.' })

  const { showConfirm } = useConfirmationModalContext()

  const onSelect = async (segment: AdminTournamentStageResponse, team: StageTeam) => {
    const isDeleteStageTeam = segment.team && segment.team.id === team.id

    const confirmed = await showConfirm({
      title: isDeleteStageTeam ? 'Delete stage winner' : 'Confirm stage winner',
      renderMessage: () => (
        <>
          <Typography component='div' variant='body1' sx={{ alignItems: 'center', display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            Are you sure you want to {isDeleteStageTeam && 'delete the winner of stage'}
            {!isDeleteStageTeam && (
              <>
                set <TeamName team={team} /> as winner of stage
              </>
            )}{' '}
            <strong>{segment.stage}</strong>?
          </Typography>
        </>
      ),
      confirmText: isDeleteStageTeam ? 'Delete' : 'Confirm',
      isDangerConfirm: isDeleteStageTeam,
    })

    if (!confirmed) return

    setKnockoutSelectionDisabled(true)

    const response = isDeleteStageTeam
      ? await apiDeleteStageTeam.requestWithParams((client) => deleteStageTeam(client, tournamentId, segment.stage))
      : await apiSetStageTeam.requestWithParams((client) => setStageTeam(client, tournamentId, segment.stage, team.id))

    if (!isSuccessStatusCode(response)) return

    await onStagesUpdated()
    setKnockoutSelectionDisabled(false)
  }

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          width: '100%',
          position: 'fixed',
          left: 0,
          backgroundColor: 'background.paper',
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)} centered={isMdUp} variant={isMdUp ? 'standard' : 'fullWidth'}>
          {groupedStages.map((stage) => (
            <Tab
              sx={{ minWidth: '80px' }}
              wrapped={!isMdUp}
              key={stage.key}
              label={isMdUp ? stage.displayName : stage.displayName}
              value={stage.key}
            />
          ))}
        </Tabs>
      </Box>
      {knockoutSelectionDisabled && <LoadingBackdrop />}
      {currentStageView && (
        <Grid container p={2} spacing={2} justifyContent='center' pt='48px'>
          {currentStageView.segments.map((segment) => (
            <Grid item key={segment.stage} xs={12} md={6} lg={3}>
              <KnockoutSegment segment={segment} onSelect={async (selectedTeam) => await onSelect(segment, selectedTeam)} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  )
}

export default KnockoutTabs
