import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material'
import { Button, Divider, Paper, Stack, Typography } from '@mui/material'
import { TeamName } from 'components'
import { AdminTournamentStageResponse, StageTeam } from 'modules/api/types/admin'
import React from 'react'

interface IProps {
  segment: AdminTournamentStageResponse
  onSelect?: (team: StageTeam) => void
}

const KnockoutSegment: React.FC<IProps> = ({ segment, onSelect }) => {
  const renderTeamButton = (placeholderKey: string) => {
    const team = segment.options[placeholderKey]
    const teamIsSelected = team && segment.team && team.id === segment.team.id
    return (
      <Button
        key={placeholderKey}
        disabled={!team}
        variant={teamIsSelected ? 'contained' : 'outlined'}
        sx={{
          justifyContent: 'space-between',
          backgroundColor: teamIsSelected ? 'primary' : 'background.paper',
          color: (theme) =>
            teamIsSelected
              ? theme.palette.getContrastText(theme.palette.primary.main)
              : theme.palette.getContrastText(theme.palette.background.paper),
        }}
        endIcon={teamIsSelected ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
        onClick={() => onSelect && onSelect(team)}
      >
        {<TeamName team={team} placeholder={placeholderKey} />}
      </Button>
    )
  }

  return (
    <Paper sx={{ p: 1, backgroundColor: 'focus' }}>
      <Stack gap={1}>
        <Typography textAlign='center' variant='subtitle1'>
          {segment.stage}
        </Typography>
        <Divider sx={{ mb: 1 }} />
        {Object.keys(segment.options).map((placeholderKey) => renderTeamButton(placeholderKey))}
      </Stack>
    </Paper>
  )
}

export default KnockoutSegment
